<template>
	<CModal title="UPDATE STATUS TO DELIVERED" :show.sync="modalState" color="info" size="lg"> 
		<form @submit.prevent="approve">
    	<!-- <div class="pl-3 pr-3 pb-3">  -->
	<fieldset class="fieldset full-line">
						<legend>Booking Information</legend>
			<h5 class="mb-2">
        			Booking # : {{details.booking_no}}
        		</h5> 
				<CRow class="mb-2">
        		<CCol lg="6">
						<small class="mr-2">Delivery Date</small>
						<CInput
		                  disabled
		                  :value="moment.unix(details.delivery_date).format('MMMM DD, YYYY')" 
		                  type="text"
						  class="mb-2"
		                />
				</CCol>	
        		
				<CCol lg="6">
						<small class="mr-2">Vehicle No.</small>
						<CInput
		                  disabled
		                  :value="details.plate_no" 
		                  type="text"
						  class="mb-2"
		                />
				</CCol>		
				
        	</CRow>
        	<hr/>
			
				<CRow>
        		<CCol lg="6">
        			<div class="form-group"> 
						<label class="mb-1">Delivered Date & Time <span class="text-danger">*</span></label>
						<Datetime format="MM/DD/YYYY H:i" v-model="bookingParams.delivery_datetime" :required="true"></Datetime>
						<!-- <Datetime format="MM/DD/YYYY H:i" v-model="bookingParams.delivery_datetime" :required="true"></Datetime> -->
					</div>
        			
        		</CCol> 
				<CCol lg="6">
        			<div class="form-group"> 
						<label class="mb-1">Received Date & Time <span class="text-danger">*</span></label>
						<Datetime format="MM/DD/YYYY H:i" v-model="bookingParams.received_datetime" :required="true"></Datetime>
						<!-- <Datetime format="MM/DD/YYYY H:i" v-model="bookingParams.received_datetime" :required="true"></Datetime> -->
					</div>
        			
        		</CCol> 
        	</CRow>  
        	<CRow>
        		<CCol lg="12">
					<label class="mb-1">Odometer Reading </label>
					<i class="fa fa-question-circle ml-1" title="Reading should be maximum of 6 characters." ></i>
        			<CInput
		                  v-model="bookingParams.odometer" 
						  type="number"
						  required
						  v-mask="'######'"
		                />
        		
				<div  class="mb-2" style="margin-top: -10px; font-size:11px " >
									<span>Current Odometer: {{ odometer_value }}
									</span>
				</div>
				<div  class="mb-2" style="margin-top: -5px; font-size:11px " >
        						<span class="text-danger">{{message}}</span>
        		</div>
			</CCol> 
        	</CRow> 
        	<CRow>
        		<CCol lg="12">

				<div class="form-group"> 
						<label class="mb-1">Farthest destination <span class="text-danger">*</span></label>
						<v-select 
							label="setting_name" 
							:options="destinationList.data"
							:reduce="item => item.destination_id"
							v-model="bookingParams.destination_id"
							placeholder="-Select-" 
						> 
							<template #search="{attributes, events}">
								<input
									class="vs__search"
									:required="!bookingParams.destination_id"
									v-bind="attributes"
									v-on="events"
								/>
							</template>
						</v-select>
					</div>

				</CCol>
        	</CRow> 
        	<CRow v-if="details.asset_type == 'vehicle'">
        		
        	</CRow> 
        	
        	<CRow>
        		<CCol lg="12">
					<label class="mb-1">Remarks </label>
        			<CInput
						  maxlength="250"
		                  v-model="bookingParams.remarks" 
		                />
        		</CCol> 
        	</CRow>  
	</fieldset>
	<fieldset class="fieldset full-line">
						<legend>References</legend>
			
    		<CRow>
        		<CCol lg="12">
					<label class="mb-1">Client Ref. No </label>
        			<CInput
		                  v-model="bookingParams.client_ref_no" 
		                />
        		</CCol> 
        	</CRow> 
			<CRow>
			<CCol lg="12">
					<div class="form-group"> 
						<label class="mb-1">Charges </label>
						<v-select 
							label="setting_name" 
							:options="[
								{
									setting_name: 'NO CHARGES',
									id: 'NO CHARGES'
								},
								{
									setting_name: 'CHARGES RECORDED',
									id: 'CHARGES RECORDED'
								}
							]"
							:reduce="item => item.id"
							
							placeholder="-Select-"
							v-model="bookingParams.charges"
							
						>
							
						</v-select>
					</div>
			</CCol>
			</CRow>
        	<CRow>
        		<CCol lg="6">
        			<label class="mb-1">Fuel (L) </label>
					<CInput
					@focus="$event.target.select()"
					:class="{'has-error' : error_list['fuel']}"
					v-model="bookingParams.fuel" 
					@keypress="isNumber($event)"
					/>		
					<small v-if="error_list['fuel']" class="text-danger" style="font-size:10px">{{ error_list['fuel'] }}</small>		
        		</CCol> 
				<CCol lg="6">
					<label class="mb-1">Fuel Amount </label>
					<CInput
					@focus="$event.target.select()"
					:class="{'has-error' : error_list['fuel-amount']}"
						v-model="bookingParams.fuel_amount" 
						@keypress="isNumber($event)"
					/>
					<small v-if="error_list['fuel-amount']" class="text-danger" style="font-size:10px">{{ error_list['fuel-amount'] }}</small>
				</CCol>
				<CCol lg="12">
					<label class="mb-1">Fuel PO</label>
					<CInput
						v-model="bookingParams.fuel_po" 
					/>
							
				</CCol>
				
        	</CRow>  
	</fieldset>
		<Expenses :bookingParams="bookingParams" :user_details="user_details" :error_list="error_list"/>
        	<!-- <CRow class="mb-1">
        		<CCol lg="12">
        			<span class="text-danger">{{ message }}</span>
        		</CCol> 
        	</CRow>  -->
			
    	<div slot="footer" class="w-100">
    		<CButton size="sm" style="border-radius: .2rem; color: white;" color="success" class="ml-1 mr-1 float-right" type="submit" v-if="!hasError">
                Confirm
            </CButton> 
			<CButton size="sm" style="border-radius: .2rem; color: white;" color="success" class="ml-1 mr-1 float-right" v-else disabled>
                Confirm
            </CButton> 
    		<CButton  size="sm" style="border-radius: .2rem; color: white;" color="danger" class="ml-1 mr-1 float-right" @click="cancel">
                Cancel
            </CButton>  
        </div>
    	</form>
    	<div slot="footer" class="w-100"></div>
    </CModal>
</template>
<script>
	import config from '../../config.js';
	import axios from '../../axios';
	import Swal from 'sweetalert2'; 
	import Datetime from 'vuejs-datetimepicker';
	import vSelect from 'vue-select'
	import 'vue-select/dist/vue-select.css';
	import '../../style.css';
	import moment from 'moment';
	import Validation from '../validation-handler-mixin.js';
	import Expenses from './includes/expenses';

	export default{
		name : 'DeliverBooking',
		mixins: [Validation],
		mounted(){

			this.$nextTick(() => {
				console.log(this.details)
				this.bookingParams.delivery_datetime = moment(new Date()).format('MM/DD/YYYY HH:MM');
				this.bookingParams.received_datetime = moment(new Date()).format('MM/DD/YYYY HH:MM');
				this.bookingParams.destination_id = this.details.destination_id;
				this.bookingParams.billable.extra_drop = this.details.billable ? this.details.billable.extra_drop : null;
				this.bookingParams.billable.extra_helper = this.details.billable ? this.details.billable.extra_helper : null;
			})
			

			
		},
		props: {
			showModal: false,
			details: {
				remarks: "",
				booking_no: "",
				plate_no:""
			}
		},
		components: {Datetime, vSelect, Expenses},
		
		data(){
			return{
				odometer_value:0, 
				user_details: JSON.parse(window.localStorage.getItem('user_data')),
				company_type: JSON.parse(window.localStorage.getItem('user_data')).company.company_type,
				dataParams: { 
					booking_id: "", 
				},
				temp: {
					odometer: ""
				},
				bookingParams: {
					remarks: "",
					client_ref_no: "", 
					fuel_po: "",
					fuel_amount: "",
					delivery_datetime: "",
					received_datetime: "",
					destination_id: "",
					odometer: "0",					
					billable: {
						parking_fee: "",
						toll_fee: "",
						other_expenses: "",
						extra_helper: "",
						extra_drop: "",
					},
					charges : "",
					fuel: ""
				},
				destinationList: {
					data: []
				},
				modalState: false,
				moment,
				message: ""
			}
		},
		watch: {
			details: {
				deep: true,
				handler(){ 
					console.log(this.details)
					this.bookingParams.remarks = this.details.remarks; 
			    	this.bookingParams.client_ref_no = this.details.client_ref_no;
					this.bookingParams.fuel_po = this.details.fuel_po;
					this.bookingParams.fuel_amount = this.details.fuel_amount;
					this.bookingParams.fuel = this.details.fuel;
				
				
				
					if(this.details.billable){ 
						this.bookingParams.billable = {...this.details.billable};
					}
					if(this.details.id != ""){
						// this.getDestination();
					}

					this.bookingParams.destination_id = this.details.destination_id

					// this.bookingParams.delivery_datetime = this.details.delivery_datetime;
					// this.bookingParams.remarks = this.details.remarks; 
					// this.bookingParams.client_ref_no = this.details.client_ref_no;
					// this.bookingParams.billable.extra_drop = this.details.billable.extra_drop;
					// this.bookingParams.billable.extra_helper = this.details.billable.extra_helper;
					this.dataParams.booking_id = this.details.id;
					if(this.details.vehicle){
						this.getOdometerData(this.details.vehicle.id)
						// if(this.details.vehicle.odometer.length > 0){
						// 	this.bookingParams.odometer = this.details.vehicle.odometer[this.details.vehicle.odometer.length-1].reading
						// }
						
					}
				}
			},
			showModal: {
				handler(){
					this.modalState = this.showModal
					if(!this.showModal){
						this.$emit('showModal', false) 
					} 
					else{
						this.getDestination();
					}
				}
			},
			modalState: {
				handler(){
					if(!this.modalState){
						this.$emit('showModal', false) 
					} 
				}
			}
		},
		methods: {
			approve(){
				if(this.bookingParams.billable.toll_fee == ""){
		    		this.bookingParams.billable.toll_fee = 0;
		    	} 
		    	if(this.bookingParams.billable.parking_fee == ""){
		    		this.bookingParams.billable.parking_fee = 0;
		    	} 
		    	if(this.bookingParams.billable.other_expenses == ""){
		    		this.bookingParams.billable.other_expenses = 0;
		    	} 
		    	if(this.bookingParams.billable.extra_drop == ""){
		    		this.bookingParams.billable.extra_drop = 0;
		    	} 
		    	if(this.bookingParams.billable.extra_helper == ""){
		    		this.bookingParams.billable.extra_helper = 0;
		    	} 
		    	
				this.message = "" 
				this.bookingParams.user_id = config.getUserDetail('id');
				this.bookingParams.status_update = true;
				var temp_date = this.bookingParams.delivery_datetime;
				this.bookingParams.temp_date = moment(temp_date).format('MM/DD/YYYY HH:mm');

				this.bookingParams.delivery_datetime = moment(this.bookingParams.delivery_datetime, "MM/DD/YYYY HH:mm").valueOf() / 1000;
				this.bookingParams.received_datetime = moment(this.bookingParams.received_datetime, "MM/DD/YYYY HH:mm").valueOf() / 1000;
				// axios.post(config.api_path+'/bookingStatusDetail', this.dataParams)
				// .then(response=>{ 

					this.bookingParams.status = "delivered";
					axios.put(config.api_path+'/booking/'+this.details.id, this.bookingParams)
					.then(response=>{
						if(response.data.status == "error"){
							this.message = response.data.message; 
						}
						else{
							var text = 'Booking successfully updated status to delivered!';
			    			Swal.fire({
								title: 'Success!',
								text,
								icon: 'success', 
							})
							.then(response=>{
								this.$emit('showModal', false)
							})
						}
					})
					

				// })
				
			},
			cancel(){ 
				this.$emit('showModal', false) 
			},
			getDestination(){
				if(this.company_type.toLowerCase() == "trucking with agency"){                                                                                                        
					axios.get(config.api_path+'/client_rate?commodity_id='+this.details.commodity_id+'&depot_id='+this.details.depot_id+'&vehicle_type_id='+this.details.vehicle_type_id+'&customer_id='+this.details.customer_id+'&origin_id='+this.details.origin_id+'&page=1&limit=10000000')
					.then(response => {
						this.destinationList = response.data;
						this.destinationList.data = this.destinationList.data.map((value, index) => {
							value.setting_name = value.destination.setting_name;
							return value
						}); 
					})
				}
				else{
					axios.get(config.api_path+'/reference/settings-list',{
						params:{
						setting_type:'destination'
						}
							})
						.then(response => {
							this.destinationList = response.data; 
							this.destinationList.data = this.destinationList.data.map((value, index) => {
			    			value.destination_id = value.id
			    			return value;
						}); 
							
						})
					}
				},
				getOdometerData(i){
		// this.data_loading.odometer = true;
				axios.get(`${config.api_path}/vehicle/odometer/${i}`).then(res => {
					const {data, status} = res.data;
					const odo = data?.odo_reading ?? "0";

					this.$set(this.bookingParams,'odometer', odo);
					this.odometer_value=odo;
					// this.data_loading.odometer = false;
				})
				.finally(fin => {
					// this.data_loading.odometer = false;
				})
			
	    },

			

		}

	}
</script>