<template>
	<CModal :title="'Booking Expenses - '+bookingParams.booking_no" :show.sync="modalState" color="info" size="xl"> 
		<form @submit.prevent="submit">
    	
    <div>
		<fieldset class="fieldset full-line">
						<legend>BOOKING EXPENSES</legend>
    		<fieldset class="fieldset broken-line">
						<legend>Billable</legend>
					<CRow>
						<CCol lg="3">
							<label class="mb-1">Toll Fees</label>
							<CInput
							@paste="onPaste($event, 'bookingParams','billable','toll_fee')"
							:class="{'has-error' : error_list['toll-fee']}"
							  @focus="$event.target.select()"
			                  v-model="bookingParams.billable.toll_fee" 
							  @keypress="isNumber($event)"
			                />
							 <small v-if="error_list['toll-fee']" class="text-danger" style="font-size:10px">{{ error_list['toll-fee'] }}</small>
						</CCol>
						<CCol lg="3">
							<label class="mb-1">Extra Drop</label>
							<CInput
							@paste="onPaste($event, 'bookingParams','billable','extra_drop')"
							  @focus="$event.target.select()"
			                  v-model="bookingParams.billable.extra_drop" 
							  @keypress="isNumber($event)"
			                />
							
						</CCol>
						<CCol lg="3">
							<label class="mb-1">Extra Helper</label>
							<CInput
							@paste="onPaste($event, 'bookingParams','billable','extra_helper')"
							  @focus="$event.target.select()"
			                  v-model="bookingParams.billable.extra_helper" 
							  @keypress="isNumber($event)"
			                />
							
						</CCol>
						<CCol lg="3">
							<label class="mb-1">Other Expenses</label>
							<CInput
							@paste="onPaste($event, 'bookingParams','billable','other_expenses')"
							@focus="$event.target.select()"
			                  v-model="bookingParams.billable.other_expenses" 
							 @keypress="isNumber($event)"
			                />
						</CCol>
						
					</CRow>
					
		
			</fieldset>
			<fieldset class="fieldset broken-line">
						<legend>Non-Billable</legend>
					<CRow>
						<CCol lg="3">
							<label class="mb-1">Backload Fee</label>
							<CInput
							@focus="$event.target.select()"
							@paste="onPaste($event, 'bookingParams','billable','backload_fee')"
			                   v-model="bookingParams.billable.backload_fee" 
							   @keypress="isNumber($event)"
							 
			                />
						</CCol>
						
						<CCol lg="3">
							<label class="mb-1">Cellphone Load</label>
							<CInput
							@focus="$event.target.select()"
							@paste="onPaste($event, 'bookingParams','billable','cellphone_load')"
			                 v-model="bookingParams.billable.cellphone_load" 
							 @keypress="isNumber($event)"
			                />
						</CCol>
						<CCol lg="3">
							<label class="mb-1"> Demurrage Fee</label>
							<CInput
							@focus="$event.target.select()"
							@paste="onPaste($event, 'bookingParams','billable','demurrage_fee')"
			                   v-model="bookingParams.billable.demurrage_fee" 
							   @keypress="isNumber($event)"
							 
			                />
							
						</CCol>

						<CCol lg="3">
							<label class="mb-1"> Facilitation</label>
							<CInput
							@focus="$event.target.select()"
							@paste="onPaste($event, 'bookingParams','billable','facilitation')"
			                   v-model="bookingParams.billable.facilitation" 
							   @keypress="isNumber($event)"
							 
			                />
						</CCol>
						
						<CCol lg="3">
							<label class="mb-1">Fuel Amount</label>
							<CInput
							@focus="$event.target.select()"
							@paste="onPaste($event, 'bookingParams','','fuel_amount')"
			                  v-model="bookingParams.fuel_amount" 
							 @keypress="isNumber($event)"
			                />
						</CCol>
						<CCol lg="3">
							<label class="mb-1">Office Supplies</label>
							<CInput
							@focus="$event.target.select()"
							@paste="onPaste($event,'bookingParams','billable','office_supplies')"
			                 v-model="bookingParams.billable.office_supplies" 
							 @keypress="isNumber($event)"
			                />
						</CCol>
					
						<CCol lg="3">
							<label class="mb-1">Parking Fees</label>
							<CInput
							@focus="$event.target.select()"
							:class="{'has-error' : error_list['parking-fee']}"
							@paste="onPaste($event,'bookingParams','billable','parking_fee')"
			                  v-model="bookingParams.billable.parking_fee" 
							 @keypress="isNumber($event)"
			                />
							 <small v-if="error_list['parking-fee']" class="text-danger" style="font-size:10px">{{ error_list['parking-fee'] }}</small>
						</CCol>

						
						<CCol lg="3">
							<label class="mb-1"> RM Labor</label>
							<CInput
							@focus="$event.target.select()"
							@paste="onPaste($event,'bookingParams','billable','rm_labor')"
			                   v-model="bookingParams.billable.rm_labor" 
							 @keypress="isNumber($event)"
			                />
						</CCol>
						<CCol lg="3">
							<label class="mb-1"> RM Spare Parts</label>
							<CInput
							@focus="$event.target.select()"
							@paste="onPaste($event,'bookingParams','billable','rm_spare_parts')"
			                  v-model="bookingParams.billable.rm_spare_parts" 
							  @keypress="isNumber($event)"
			                />
						</CCol>
					
					<CCol lg="3">
							<label class="mb-1">SOP/Traffic Violation</label>
							<CInput
							@focus="$event.target.select()"
							@paste="onPaste($event,'bookingParams','billable','traffic_violation')"
			                  v-model="bookingParams.billable.traffic_violation" 
							 @keypress="isNumber($event)"
			                />
						
						</CCol>
					
		
						<CCol lg="3">
							<label class="mb-1"> Travel Expenses</label>
							<CInput
							@focus="$event.target.select()"
							@paste="onPaste($event,'bookingParams','billable','travel_expenses')"
			                    v-model="bookingParams.billable.travel_expenses"
								@keypress="isNumber($event)" 
			                />
						</CCol>
						
						<CCol lg="3">
							<label class="mb-1">Trip Allowance</label>
							<CInput
							@focus="$event.target.select()"
							@paste="onPaste($event,'bookingParams','billable','trip_allowance')"
			                 v-model="bookingParams.billable.trip_allowance" 
							 @keypress="isNumber($event)"
			                />
						</CCol>
					
						<CCol lg="3">
							<label class="mb-1"> Toll Fee-Non Billable</label>
							<CInput
							@focus="$event.target.select()"
							@paste="onPaste($event,'bookingParams','billable','toll_fee_non_billable')"
			                  v-model="bookingParams.billable.toll_fee_non_billable" 
							  @keypress="isNumber($event)"
			                />
						</CCol>
							
				 </CRow>
					<CRow>
						
						
						
					</CRow>
					<CRow>
						<CCol lg="12" style="text-align:right">
							 
                         	 <label>Billable Total: </label>
                         	 <strong >
								  {{ billable_total | number_format('currency') }}
							  </strong>
                       
					 </CCol>
					 <CCol lg="12" style="text-align:right">
							 
                         	 <label >Non-Billable Total: </label>
                         	 <strong >
								  {{ non_billable_total | number_format('currency')}}
							  </strong>
                       
					 </CCol>
					 <CCol lg="12" style="text-align:right">
							 
                         	 <label >Actual Total: </label>
                         	 <strong >
								  {{ actual_amount | number_format('currency') }}
							  </strong>
                       
					 </CCol>
					 
					</CRow>
			</fieldset>
			<fieldset class="fieldset broken-line">
						<legend>LIQUIDATION</legend>
					<CRow>
						<CCol lg="6">
							<label class="mb-1">Travel Fund/Budget Amount</label>
							<CInput

							@focus="$event.target.select()"
							@paste="onPaste($event,'bookingParams','billable','budget_amount')"
							 v-model="bookingParams.billable.budget_amount"
							 @keypress="isNumber($event)" 
			                />
						</CCol>
						<CCol lg="6">
							<div class="form-group"> 
								<label class="mb-1">Cash Received by</label>
								<v-select 
									label="name" 
									:options="cash_received_by_option"
									:reduce="item => item.id"
									v-model="bookingParams.billable.cash_received_by"
									placeholder="Select.." 
								>
								</v-select>
							</div>
						</CCol>
						
					</CRow>
					<CRow>
						<CCol lg="4">
							<label class="mb-1">Cash Advance</label>
							<CInput
							@focus="$event.target.select()"
							@paste="onPaste($event,'bookingParams','billable','cash_advance')"
			                  v-model="bookingParams.billable.cash_advance"
							  @keypress="isNumber($event)"
			                />
						</CCol>
						<CCol lg="4">
							<label class="mb-1">Cash Returned <small>(Suggested amount: <a href="javascript:void(0)" title="Click to copy suggested amount." @click="useSuggested('cash_returned', cash_returned)">{{cash_returned | number_format('currency')}}</a>)</small></label>
							<CInput
							@focus="$event.target.select()"
							@paste="onPaste($event,'bookingParams','billable','cash_returned')"
			                 v-model="bookingParams.billable.cash_returned"
							 @keypress="isNumber($event)"
							 type="search"
			                />
						</CCol>
						<CCol lg="4">
							<label class="mb-1">Liquidated</label>
							<CInput
							@focus="$event.target.select()"
							@paste="onPaste($event,'bookingParams','billable','liquidated')"
			                 v-model="bookingParams.billable.liquidated"
							 @keypress="isNumber($event)"
			                />
						</CCol>
						 
						
					</CRow>
					<CRow>
						<CCol lg="12">
							<label class="mb-1">Remarks</label>
							<CTextarea
			                 
			                 v-model="bookingParams.billable.remarks"
							 
			                />
						</CCol>
	
					</CRow>
					
					<CRow>
						<CCol  lg="12" style="text-align:right">
							 
                         	<h5 class="mb-0">Cash Balance: 
								  <span style="color: blue">{{ cash_balance | number_format('currency')}}</span> 
							</h5>
							<label><small>(Show <a href="javascript:void(0)" title="Budget Amount/Travel Fund - Actual Total - Cash Returned -Cash Advance">{{'Computation'}}</a>)</small></label>
							<!-- <small>Budget amount - Actual Total - Cash Returned -Cash Advance</small> -->
                       
					 </CCol>
					 <CCol lg="12" style="text-align:right">
							 
                         	 <h5 class="mb-0">Unliquidated: 
								<span style="color: blue">
									{{unliquidated | number_format('currency')}}
								</span> 
								  
							</h5>
							<!-- <small>Actual Total - Liquidated</small> -->
							<label><small>(Show <a href="javascript:void(0)" title="Actual Total - Liquidated">{{'Computation'}}</a>)</small></label>
                         	
                       
					 </CCol>
					</CRow>
					
		
			</fieldset>
			
			<div slot="footer" class="w-100">
			<CButton 
				size="sm" style="border-radius: .2rem; color: white;" color="info" class="ml-1 mr-1 float-right px-3" type="submit" 
				v-if="!hasError && config.getPermission('booking_expenses').create">
               Save
            </CButton>  
			<CButton size="sm" style="border-radius: .2rem; color: white;" color="info" class="ml-1 mr-1 float-right px-3" v-else disabled>
               Save
            </CButton>  
			<CButton size="sm" style="border-radius: .2rem; color: white;" color="danger" class="ml-1 mr-1 float-right" @click="cancel">
                Close
            </CButton>  
    		
        </div>
		</fieldset>
					
    </div>
		
        </form>
    	<div slot="footer" class="w-100"></div>
    </CModal>
</template>
<script>
	import config from '../../config.js';
	import axios from '../../axios';
	import Swal from 'sweetalert2'; 
	import Datepicker from 'vuejs-datepicker';
	import vSelect from 'vue-select'
	import 'vue-select/dist/vue-select.css';
	import Validation from '../validation-handler-mixin.js';
	import '../../style.css';

	export default{
		mixins: [Validation],
		props: {
			showModal: false,
			details: {}
		},
		components: {vSelect},
		data(){
			return{
				config,
				bookingParams: {billable : {}},
				modalState: false,
				cash_received_by_option : []
			}
		},
		
		watch: {
			details: {
				immediate : true,
				deep: false,
				handler(val){
					if(val) {
						
						this.cash_received_by_option = [];
						if(val.driver)
							this.cash_received_by_option.push({
								id : val.driver.id,
								name : val.driver.name
							})
						if(val.helper_a)
							this.cash_received_by_option.push({
								id : val.helper_a.id,
								name : val.helper_a.name
							})		
						
						if(val.helper_b)
							this.cash_received_by_option.push({
								id : val.helper_b.id,
								name : val.helper_b.name
							})
					}
					// remove duplicate
					this.cash_received_by_option = [...new Map(this.cash_received_by_option.map(item => [item['id'], item])).values()]
					
				}
			},
			showModal: {
				handler(val){
					this.modalState = this.showModal
					if(!this.showModal){
						this.error_list = {};
						this.$emit('showModal', false);
						
					} 
					if(val) {
						this.bookingParams = (({id, booking_no, billable, fuel_amount}) => ({id, booking_no, billable, fuel_amount}))(this.details)
						this.bookingParams.billable = (this.bookingParams.billable) ? this.bookingParams.billable : {}; // workaround when billable is null
					}
				}
			},
			modalState: {
				handler(val){
					if(!this.modalState){
						this.$emit('showModal', false) 
					}
					// this.getData()
				}
			}
		},
		computed: {
			billable_total() {
				let total = 0;
				total += (this.bookingParams.billable.toll_fee) ? parseFloat(this.bookingParams.billable.toll_fee) : 0;
				total += (this.bookingParams.billable.other_expenses) ? parseFloat(this.bookingParams.billable.other_expenses) : 0;
				total += (this.bookingParams.billable.extra_drop) ? parseFloat(this.bookingParams.billable.extra_drop) : 0;
				total += (this.bookingParams.billable.extra_helper) ? parseFloat(this.bookingParams.billable.extra_helper) : 0;
				


				return total; 
			},
			non_billable_total() {
				let total = 0;
				total += (this.bookingParams.billable.traffic_violation) ? parseFloat(this.bookingParams.billable.traffic_violation) : 0;
				total += (this.bookingParams.billable.parking_fee) ? parseFloat(this.bookingParams.billable.parking_fee) : 0;

				total += (this.bookingParams.billable.trip_allowance) ? parseFloat(this.bookingParams.billable.trip_allowance) : 0;
				total += (this.bookingParams.billable.office_supplies) ? parseFloat(this.bookingParams.billable.office_supplies) : 0;
				total += (this.bookingParams.billable.cellphone_load) ? parseFloat(this.bookingParams.billable.cellphone_load) : 0;
				total += (this.bookingParams.fuel_amount) ? parseFloat(this.bookingParams.fuel_amount) : 0;
				
				total += (this.bookingParams.billable.rm_labor) ? parseFloat(this.bookingParams.billable.rm_labor) : 0;
				total += (this.bookingParams.billable.travel_expenses) ? parseFloat(this.bookingParams.billable.travel_expenses) : 0;
				total += (this.bookingParams.billable.facilitation) ? parseFloat(this.bookingParams.billable.facilitation) : 0;
				total += (this.bookingParams.billable.rm_spare_parts) ? parseFloat(this.bookingParams.billable.rm_spare_parts) : 0;
				total += (this.bookingParams.billable.toll_fee_non_billable) ? parseFloat(this.bookingParams.billable.toll_fee_non_billable) : 0;
				total += (this.bookingParams.billable.demurrage_fee) ? parseFloat(this.bookingParams.billable.demurrage_fee) : 0;
				total += (this.bookingParams.billable.backload_fee) ? parseFloat(this.bookingParams.billable.backload_fee) : 0;

				return total; 
			},
			actual_amount() {
				return this.non_billable_total + this.billable_total
			},
			cash_balance() {
				let total = 0;
				total += (this.bookingParams.billable.budget_amount) ? parseFloat(this.bookingParams.billable.budget_amount) : 0;
				total -= this.actual_amount;
				total -= (this.bookingParams.billable.cash_returned) ? parseFloat(this.bookingParams.billable.cash_returned) : 0;
				total -= (this.bookingParams.billable.cash_advance) ? parseFloat(this.bookingParams.billable.cash_advance) : 0;


				return total; 
			},
			unliquidated() {
				let total = 0;
				total += this.actual_amount;
				total -= (this.bookingParams.billable.liquidated) ? parseFloat(this.bookingParams.billable.liquidated) : 0;

				return total; 
			},
			cash_returned() {
				let total = 0;
				total += (this.bookingParams.billable.budget_amount) ? parseFloat(this.bookingParams.billable.budget_amount) : 0;
				total -= this.actual_amount;
				total -= (this.bookingParams.billable.cash_advance) ? parseFloat(this.bookingParams.billable.cash_advance) : 0;


				return total; 
			}
		},
		methods: {
			onPaste(evt, parent, object, key) {
				let prev = evt.target.value;
				let value = evt.clipboardData.getData('text');
				
				let clean = value?.replace(/,/g, "") ?? '';

				if(isNaN(clean)) {
					alert('Invalid number value.')
					evt.preventDefault();
					evt.target.value = prev;
					if(object) {
						this[parent][object][key] = prev;
					}
					else this[parent][key] = prev;
					return true;
				}
				evt.preventDefault()
				evt.target.value = clean;
				if(object)
					this.$set(this[parent][object], key, clean)
				else this.$set(this[parent], key, clean)

				console.log(this[parent][object][key])
				return true;
				
			},
			clear() {
				this.bookingParams = {
					material_code: "",
			        description: "",
			        unit_price: "",
			        quantity: "",
			        rate: "",
			        total_charge: "",
			        charge_type: "",
			        charge_to: "",
			        charge_ref_no: "",
			        status: "",
			        booking_id: "",
				}
			},

			useSuggested(type, val) {
				this.bookingParams.billable[type] = val;
			},
			
			submit(){
					
					// this.bookingParams.booking_id = this.details.id;
					// this.bookingParams.rate= this.bookingParams.rate ? this.bookingParams.rate: null;
					this.bookingParams.billable.actual_amount = this.actual_amount;
					this.bookingParams.billable.unliquidated = this.unliquidated;
					this.bookingParams.billable.cash_balance = this.cash_balance;

					for (const key in this.bookingParams.billable) {

						if (this.bookingParams.billable.hasOwnProperty(key)) {
							if(this.bookingParams.billable[key] && this.bookingParams.billable[key] == '.') this.bookingParams.billable[key] = null;
							else
							this.bookingParams.billable[key] = this.bookingParams.billable[key] ? this.bookingParams.billable[key] : null;
							
						}
					}
					
					this.$emit('showloader', true);
					if(this.bookingParams.id){
						let billable_id = this.bookingParams.billable.id ?? null;
						axios.put(`${config.api_path}/booking-expenses/${billable_id}`, this.bookingParams)
						.then(response => {

							
						
							Swal.fire({
								title: 'Success!',
								text: 'Expenses successfully updated.',
								icon: 'success', 
							})
							.then(response=>{ 
								
								this.$emit('showModal', false)
							})
						})
						.catch(err => {
							console.error(err)
						})
						.finally(() => {
							this.$emit('showloader', false);
						})
					}
			},
			cancel(){ 
				this.$emit('showModal', false) 
			},
			
			getData(){ 
				this.clear()
				axios.get(config.api_path+'/booking_charges?booking_id='+this.bookingParams.id)
			},
		
		}

	}
</script>
<style>
/* .mudal > .modal > .modal-dialog > .modal-content{ */
	/* max-height: 95vh; */
	/* overflow-y: scroll; */
/* } */
label.font-w600 {
	font-weight: 600;
}
</style>