<template>
	<div>
		<BlockUI :message="defaultMsg" :html="html" v-if="isLoading"></BlockUI>
		<CRow><CButton size="sm" class="btn btn-link back-btn" 
					@click="backToTable()"><i class="fa fa-arrow-left"></i>Back</CButton>
		</CRow>
		
		<CCard class="p-2"> 
			<CCardHeader >
				<CRow>
					<CCol lg="6">
						<h5>Booking #{{dataParams.booking_no}}</h5>
					</CCol>
			     	<CCol lg="6">
						<div class="d-flex" style="flex-direction:row-reverse">
							
							<CButton class="mr-2" color="btn btn-outline-info"  size="sm" @click="showHistoryModal = true">History</CButton> 
	      					<CButton class="mr-2" color="btn btn-outline-info" v-if="config.getPermission('booking').print" size="sm" @click="print" >Print</CButton>  
							<CButton class="mr-2" color="btn btn-outline-warning" size="sm" @click="openApproveModal(dataParams)" v-if="dataParams.status == 'under review' && config.getPermission('booking').approve && company_type.toLowerCase() != 'trucking'">Approve</CButton>  
			      			<CButton class="mr-2" color="btn btn-outline-warning" size="sm" @click="openDispatchModal(dataParams)" v-if="
			      				((dataParams.status == 'approved' || dataParams.status == 'under review') && 
			      				config.getPermission('booking').dispatch && 
			      				company_type.toLowerCase() == 'trucking') ||
			                	((dataParams.status == 'approved') &&
			                	config.getPermission('booking').dispatch && 
			                	company_type != 'trucking')">Dispatch</CButton> 
			      			<CButton class="mr-2" color="btn btn-outline-warning" size="sm" @click="openDeliverModal(dataParams)" v-if="dataParams.status == 'dispatched' && config.getPermission('booking').deliver">Delivered</CButton> 
			      			<CButton class="mr-2" color="btn btn-outline-warning" size="sm" @click="openCompleteModal(dataParams)" v-if="dataParams.status == 'delivered' && config.getPermission('booking').complete">Complete</CButton> 
							<CButton class="mr-2" color="primary" variant="ghost"
								v-if="config.getPermission('booking_expenses').create && config.getPermission('booking_expenses').view" size="sm" @click="openExpensesModal(dataParams)">Expenses
							</CButton>  
			      			<CButton class="mr-2" color="primary" variant="ghost"
							  	v-if="config.getPermission('booking_charges').allowCrud" size="sm" @click="openChargesModal(dataParams)">Charges
							</CButton>  
							<template v-if="!is_trucking()">
								<CButton class="mr-2" color="primary" variant="ghost" v-if="config.getPermission('dr').allowCrud" size="sm" 
								@click="viewDr(dataParams)">DR's</CButton> 
			      				<CButton class="mr-2" color="primary" variant="ghost" v-if="config.getPermission('mts').allowCrud" size="sm" 
								@click="viewMts(dataParams)">MTS's</CButton> 
							</template>
			      			
			      			
			      		</div>
					</CCol>
				</CRow>
			</CCardHeader>
			<CCardBody>
			<fieldset class="full-line rounded">
						<legend>Booking Information</legend>
				<CRow class="ml-3 mr-3">
						<CCol lg="4">
							<div class="form-group">
                         	 <small class="">Booking Date</small>
                         	 <strong class="form-text mt-0">
								  {{dataParams.created_at ? moment(dataParams.created_at).format('MMMM DD, YYYY')  : "-"}}
							</strong>
							</div>
						
						</CCol>
						<CCol lg="4">
							<div class="form-group">
                         	 <small class="">Delivery Date</small>
                         	 <strong class="form-text mt-0">
								 {{dataParams.delivery_date? moment.unix(dataParams.delivery_date).format('MMMM DD, YYYY') : "-"}}
							</strong>
							</div>
							
						</CCol> 
						<CCol lg="4">
							 <div class="form-group">
                         	 <small class="">Status</small>
                         	 <strong class="form-text mt-0 text-uppercase">
								  {{dataParams.status ? dataParams.status : "-"}}
							  </strong>
                        </div>
							
						</CCol> 
						

				</CRow>
				<CRow class="ml-3 mr-3">
						<CCol lg="4">
						<div class="form-group">
                         	 <small class="">Customer</small>
                         	 <strong class="form-text mt-0">
								  {{dataParams.customer ? dataParams.customer.customer_name : "-"}}</strong>
                        </div>
						</CCol>
						<CCol lg="4">
						<div class="form-group">
                         	 <small class="">Booking Type</small>
                         	 <strong class="form-text mt-0">
								  {{dataParams.booking_type ? dataParams.booking_type.setting_name : "-"}}
							  </strong>
                        </div>
						</CCol>
						<CCol lg="4">
							<div class="form-group">
                         	 <small class="">Commodity</small>
                         	 <strong class="form-text mt-0">
								  {{dataParams.commodity? dataParams.commodity.setting_name : "-"}}
							</strong>
							</div>
							
						</CCol> 
						
						 
						
						
				</CRow>
				<CRow class="ml-3 mr-3">
					
						<CCol lg="4">
							 <div class="form-group">
                         	 <small class="">Depot</small>
                         	 <strong class="form-text mt-0">
								  {{dataParams.depot ? dataParams.depot.setting_name : "-"}}
							  </strong>
							 </div>
							
						</CCol>
						
						
				</CRow>
				<CRow class="ml-3 mr-3">
						<CCol lg="4">
							<div class="form-group">
                         	 <small class="">Origin</small>
                         	 <strong class="form-text mt-0">
								 {{dataParams.origin ? dataParams.origin.setting_name : "-"}}
							</strong>
							</div>
							
						</CCol>
						
						<CCol lg="4">
							<div class="form-group">
                         	 <small class="">Destination</small>
                         	 <strong class="form-text mt-0">
								 {{dataParams.destination ? dataParams.destination.setting_name : "-"}}
							</strong>
							</div>
							
						</CCol> 
							
				</CRow>
				<CRow class="ml-3 mr-3">
					
						
						
				</CRow> 
				<CRow class="ml-3 mr-3">
					
						<CCol lg="4">
							<div class="form-group">
                         	 	<small class="">Route Code</small>
                         	 	<strong class="form-text mt-0">
								 	{{(dataParams.route_code != null && dataParams.route_code != '') ? dataParams.route_code : "-"}}
								</strong>
							</div>
						</CCol> 
						<CCol lg="4">
							<div class="form-group">
                         	 	<small class="">No. of Trips</small>
                         	 	<strong class="form-text mt-0">
								 	{{dataParams.no_of_trips ? dataParams.no_of_trips : "0"}}
								</strong>
							</div>
								
						</CCol> 
				</CRow> 
			</fieldset>
			<fieldset class="full-line rounded">
				<legend>Assigned Vehicle</legend>
				<CRow class="ml-3 mr-3">
					<CCol lg="4" v-if="dataParams.asset_type == 'vehicle'">
							<div class="form-group">
                         	 	<small class="">Subcon</small>
                         	 	<strong class="form-text mt-0">
								 	{{dataParams.is_subcon == 0 ? "No" : "Yes"}}
								</strong>
							</div>
					</CCol> 
					<CCol lg="8" v-if="dataParams.is_subcon == 1">
							<div class="form-group">
                         	 	<small class="">Trucker</small>
                         	 	<strong class="form-text mt-0">
								 	{{dataParams.vendor ? dataParams.vendor.vendor_name : "-"}}
								</strong>
							</div>
					</CCol> 
					
					<CCol lg="4">
							<div class="form-group">
                         	 	<small class="">Vehicle No.</small>
                         	 	<strong class="form-text mt-0">
								 	{{dataParams.vehicle ? dataParams.vehicle.plate_no : "-"}}
								</strong>
							</div>
					</CCol> 
					<CCol lg="4">
							<div class="form-group">
                         	 	<small class="">Truck Type</small>
                         	 	<strong class="form-text mt-0">
								 	{{dataParams.vehicle_type ? dataParams.vehicle_type.setting_name : "-"}}
								</strong>
							</div>
					</CCol> 

			    </CRow>
			</fieldset>
		    <fieldset class="full-line rounded">
				<legend>Assigned Personnel</legend>
					<CRow class="ml-3 mr-3">
						<CCol lg="6" class="text-capitalize" v-if="company_type_withagency.toLowerCase() != 'trucking with no agency'">
							<div class="form-group">
                         	 	<small class="">Driver Agency</small>
                         	 	<strong class="form-text mt-0">
								 	{{dataParams.agency ? dataParams.agency.vendor_name : "-"}}
								</strong>
							</div>
					    </CCol> 
						<CCol lg="6">
							<div class="form-group">
                         	 	<small class="">Driver</small>
                         	 	<strong class="form-text mt-0">
								 	{{dataParams.driver ? dataParams.driver.name : "-"}}
								</strong>
							</div>
					    </CCol> 
							
							
						<CCol lg="6" class="text-capitalize" v-if="company_type_withagency.toLowerCase() != 'trucking with no agency'">
							<div class="form-group">
                         	 	<small class="">Helper 1 Agency</small>
                         	 	<strong class="form-text mt-0">
								 	{{dataParams.helper1agency ? dataParams.helper1agency.vendor_name : "-"}}
								</strong>
							</div>
					    </CCol> 
						<CCol lg="6">
							<div class="form-group">
                         	 	<small class="">Helper 1</small>
                         	 	<strong class="form-text mt-0">
								 	{{dataParams.helper_a ? dataParams.helper_a.name : "-"}}
								</strong>
							</div>
					    </CCol> 
						<CCol lg="6" class="text-capitalize" v-if="company_type_withagency.toLowerCase() != 'trucking with no agency'">
							<div class="form-group">
                         	 	<small class="">Helper 2 Agency</small>
                         	 	<strong class="form-text mt-0">
								 	{{dataParams.helper2agency ? dataParams.helper2agency.vendor_name : "-"}}
								</strong>
							</div>
					    </CCol> 
						<CCol lg="6">
							<div class="form-group">
                         	 	<small class="">Helper 2</small>
                         	 	<strong class="form-text mt-0">
								 	{{dataParams.helper_b ? dataParams.helper_b.name : "-"}}
								</strong>
							</div>
					    </CCol> 
					</CRow>
					
					<CRow class="ml-3 mr-3">
						<CCol lg="6">
							<div class="form-group">
                         	 	<small class="">Remarks</small>
                         	 	<strong class="form-text mt-0">
								 	{{dataParams.remarks ? dataParams.remarks : "-"}}
								</strong>
							</div>
					    </CCol> 
						
						
					</CRow>  

			</fieldset>

			<fieldset class="full-line rounded">
				<legend>References</legend>
					<CRow class="ml-3 mr-3 mt-0">
						<CCol lg="4">
							<div class="form-group">
                         	 	<small class="">Client Ref No.</small>
                         	 	<strong class="form-text mt-0">
								 	{{dataParams.client_ref_no ? dataParams.client_ref_no : "-"}}
								</strong>
							</div>
						</CCol> 
						<CCol lg="4">
							<div class="form-group">
                         	 	<small class="">Other Ref No.</small>
                         	 	<strong class="form-text mt-0">
								 	{{dataParams.other_ref_no ? dataParams.other_ref_no : "-"}}
								</strong>
							</div>
						</CCol> 
						<CCol lg="4">
							<div class="form-group">
                         	 	<small class="">Charges</small>
                         	 	<strong class="form-text mt-0">
								 	{{dataParams.charges ? dataParams.charges : "-"}}
								</strong>
							</div>
						</CCol> 
						
						 
					</CRow>
					<CRow class="ml-3 mr-3 mt-0">
						<CCol lg="4">
							<div class="form-group">
                         	 	<small class="">Fuel</small>
                         	 	<strong class="form-text mt-0">
								 	{{dataParams.fuel ? dataParams.fuel : "-"}}
								</strong>
							</div>
						</CCol> 
						<CCol lg="4">
							<div class="form-group">
                         	 	<small class="">Fuel PO</small>
                         	 	<strong class="form-text mt-0">
								 	{{dataParams.fuel_po ? dataParams.fuel_po : "-"}}
								</strong>
							</div>
						</CCol> 
						<CCol lg="4">
							<div class="form-group">
                         	 	<small class="">Fuel Amount</small>
                         	 	<strong class="form-text mt-0">
								 	{{dataParams.fuel_amount ? dataParams.fuel_amount : "-"}}
								</strong>
							</div>
						</CCol> 
						
						
						
					</CRow>
					<CRow class="ml-3 mr-3 mt-0">
						<CCol lg="4">
							<div class="form-group">
                         	 	<small class="">Remarks 2</small>
                         	 	<strong class="form-text mt-0">
								 	{{(!dataParams.remarks_b || dataParams.remarks_b == "") ? "-" : dataParams.remarks_b}}
								</strong>
							</div>
						</CCol> 
					
						
					</CRow>
					
			</fieldset>
				
			<fieldset class="full-line rounded" v-if="user_details.company != null && user_details.company.company_type.includes('Trucking') && (dataParams.status == 'completed' || dataParams.status == 'delivered')">
			<legend>Expenses</legend>
					
				<!-- <CRow class="ml-1 mr-1">	
					<CCol lg="6">
						<fieldset class="main-fieldset">
							<legend class="sub-legend">Billable</legend>
						<CRow  class="ml-1 mr-1">
							<CCol lg="6">
							      <div class="form-group">
                         	 	   <small class="">Toll Fees</small>
                         	 	   <strong class="form-text mt-0">
									{{dataParams.billable ? dataParams.billable.toll_fee : "-"}}
								    </strong>
							     </div>
					        </CCol> 
							<CCol lg="6">
							      <div class="form-group">
                         	 	   <small class="">Other Expenses</small>
                         	 	   <strong class="form-text mt-0">
									{{dataParams.billable ? dataParams.billable.other_expenses : "-"}}
								    </strong>
							     </div>
					        </CCol> 
							
						
						</CRow>
						<CRow  class="ml-1 mr-1">
							<CCol lg="6">
							      <div class="form-group">
                         	 	   <small class="">Extra Drop</small>
                         	 	   <strong class="form-text mt-0">
									{{dataParams.billable ? (dataParams.billable.extra_drop ? dataParams.billable.extra_drop : "-") : "-"}}
								    </strong>
							     </div>
					        </CCol> 
							<CCol lg="6">
							      <div class="form-group">
                         	 	   <small class="">Extra Helper</small>
                         	 	   <strong class="form-text mt-0">
									{{dataParams.billable ? (dataParams.billable.extra_helper ? dataParams.billable.extra_helper : "-") : "-"}}
								    </strong>
							     </div>
					        </CCol> 
							
						</CRow> 
							<br>	
						</fieldset>
						</CCol>
					<CCol lg="6">
						<fieldset class="main-fieldset">
								<legend class="sub-legend">Non-Billable</legend>
						<CRow  class="ml-1 mr-1">
							<CCol lg="6">
							      <div class="form-group">
                         	 	   <small class="">Parking Fees</small>
                         	 	   <strong class="form-text mt-0">
									{{dataParams.billable ? dataParams.billable.parking_fee : "-"}}
								    </strong>
							     </div>
					        </CCol> \
							<CCol lg="6">
							      <div class="form-group">
                         	 	   <small class="">Toll Fees-Non Billable</small>
                         	 	   <strong class="form-text mt-0">
									{{dataParams.billable ? (dataParams.billable.toll_fee_non_billable ? dataParams.billable.toll_fee_non_billable : "-") : "-"}}
								    </strong>
							     </div>
					        </CCol> 
							
						
						</CRow>
						<CRow  class="ml-1 mr-1">
							<CCol lg="6">
							      <div class="form-group">
                         	 	   <small class="">Demurrage Fees</small>
                         	 	   <strong class="form-text mt-0">
									{{dataParams.billable ? (dataParams.billable.demurrage_fee ? dataParams.billable.demurrage_fee : "-") : "-"}}
								    </strong>
							     </div>
					        </CCol> 
							<CCol lg="6">
							      <div class="form-group">
                         	 	   <small class="">Backload Fees</small>
                         	 	   <strong class="form-text mt-0">
									{{dataParams.billable ? (dataParams.billable.backload_fee ? dataParams.billable.backload_fee : "-") : "-"}}
								    </strong>
							     </div>
					        </CCol> 
								
						</CRow> 
						<br>
						
				
						
						</fieldset>
					</CCol>
				</CRow> -->
				<CRow class="mr-0 ml-0 pl-2 pr-2"> 
				<!-- Billable -->
			<CCol lg="6">
				<div class="table-responsive-sm table-responsive-md">
					<table class="table table-borderless table-sm text-left">
						<thead class="bg-light" style="font-weight:bold;">
							<tr>
								<td class="table-h" colspan="12">Billable</td>
							</tr>
						</thead>
						<tbody >
							<tr >
								<td class="table-pr-data" colspan="4" >Toll Fees</td>
								<td class="table-pr-data-2" colspan="2" style="font-weight:bold">{{(dataParams.billable ? dataParams.billable.toll_fee : "-") | number_format('currency') }}</td>
								<td class="table-pr-data" colspan="4" >Other Expenses</td>
								<td class="table-pr-data-2" colspan="2" style="font-weight:bold">{{(dataParams.billable ? dataParams.billable.other_expenses : "-") | number_format('currency')}}</td>
							</tr>

							<tr >
								<td class="table-pr-data" colspan="4" >Extra Drop</td>
								<td class="table-pr-data-2" colspan="2" style="font-weight:bold">{{(dataParams.billable ? (dataParams.billable.extra_drop ? dataParams.billable.extra_drop : "-") : "-") | number_format('currency')}}</td>
								<td class="table-pr-data" colspan="4" >Extra Helper</td>
								<td class="table-pr-data-2" colspan="2" style="font-weight:bold">{{(dataParams.billable ? (dataParams.billable.extra_helper ? dataParams.billable.extra_helper : "-") : "-") | number_format('currency')}}</td>
							</tr>
							
						</tbody>	
					</table>

				</div>  
                      
            </CCol>
			<CCol lg="6">
				<div class="table-responsive-sm table-responsive-md">
					<table class="table table-borderless table-sm text-left">
						<thead class="bg-light" style="font-weight:bold;">
							<tr>
								<td class="table-h" colspan="12">Non-Billable</td>
							</tr>
						</thead>
						<tbody >
							<tr >
								<td class="table-pr-data" colspan="4" >Parking Fees</td>
								<td class="table-pr-data-2" colspan="2" style="font-weight:bold">{{(dataParams.billable ? (dataParams.billable.parking_fee ? dataParams.billable.parking_fee : "-") : "-") | number_format('currency')}}</td>
								<td class="table-pr-data" colspan="4" >Tool Fees-Non Billable</td>
								<td class="table-pr-data-2" colspan="2" style="font-weight:bold">{{(dataParams.billable ? (dataParams.billable.toll_fee_non_billable ? dataParams.billable.toll_fee_non_billable : "-") : "-") | number_format('currency')}}</td>
							</tr>

							<tr >
								<td class="table-pr-data" colspan="4" >Demmurage Fees</td>
								<td class="table-pr-data-2" colspan="2" style="font-weight:bold">{{(dataParams.billable ? (dataParams.billable.demurrage_fee ? dataParams.billable.demurrage_fee : "-") : "-") | number_format('currency')}}</td>
								<td class="table-pr-data" colspan="4" >Backload Fees</td>
								<td class="table-pr-data-2" colspan="2" style="font-weight:bold">{{(dataParams.billable ? (dataParams.billable.backload_fee ? dataParams.billable.backload_fee : "-") : "-") | number_format('currency')}}</td>
							</tr>
							
						</tbody>	
					</table>

				</div>  
                      
            </CCol>
			
						
			</CRow> 
			</fieldset>	
			<fieldset class="full-line rounded">
			<legend>Pricing Details</legend>
					
				<!-- <CRow class="ml-1 mr-1">	
					<CCol lg="6">
						<fieldset class="main-fieldset">
							<legend class="sub-legend">Personnel Rates</legend>
						<CRow  class="ml-1 mr-1">
							<CCol lg="3">
				                  <small> Driver Rate</small>
		    	               </CCol>
			                   <CCol lg="3" class="text-left">
			                      <strong style="font-size:12px" >{{dataParams.driver_rate | number_format('currency')}}</strong>
			                </CCol>
							<CCol lg="4">
				                  <small> Driver Allowance</small>
		    	               </CCol>
			                   <CCol lg="2" class="text-left">
			                      <strong style="font-size:12px" >{{dataParams.driver_allowance | number_format('currency')}}</strong>
			                </CCol>
							
						
						</CRow>
						<CRow  class="ml-1 mr-1 mt-0">
							<CCol lg="3">
				                  <small> Helper 1 Rate</small>
		    	               </CCol>
			                   <CCol lg="3" class="text-left">
			                      <strong style="font-size:12px" >{{dataParams.helper_a_rate | number_format('currency')}}</strong>
			                </CCol>
							<CCol lg="4">
				                  <small> Helper 1 Allowance</small>
		    	               </CCol>
			                   <CCol lg="2" class="text-left">
			                      <strong style="font-size:12px" >{{dataParams.helper_a_allowance | number_format('currency')}}</strong>
			                </CCol>
							
						</CRow> 
						
						<CRow  class="ml-1 mr-1">
							<CCol lg="3">
				                  <small> Helper 2 Rate</small>
		    	               </CCol>
			                   <CCol lg="3" class="text-left">
			                      <strong style="font-size:12px" >{{dataParams.helper_b_rate | number_format('currency')}}</strong>
			                </CCol>
							<CCol lg="4">
				                  <small> Helper 2 Allowance</small>
		    	               </CCol>
			                   <CCol lg="2" class="text-left">
			                      <strong style="font-size:12px" >{{dataParams.helper_b_allowance | number_format('currency')}}</strong>
			                </CCol>
							
						</CRow> 
							<br>	
						</fieldset>
						</CCol>
					<CCol lg="6">
						<fieldset class="main-fieldset">
							<legend class="sub-legend">Client Rates</legend>
							<CRow  class="ml-1 mr-1">
								<CCol lg="6">
				                  <strong style="font-size:11px"> Client Rate</strong>
		    	               </CCol>
			                   <CCol lg="6" class="text-left">
			                      <strong style="font-size:11px" >{{dataParams.client_rate | number_format('currency')}}</strong>
			                   </CCol>
							</CRow>
							<CRow  class="ml-1 mr-1">
							   <CCol lg="6">
				                  <strong style="font-size:11px"> No. of Trips </strong>
		    	               </CCol>
			                   <CCol lg="6" class="text-left">
			                      <strong style="font-size:11px" >{{dataParams.no_of_trips ? dataParams.no_of_trips : "-"}}</strong>
			                   </CCol>
							</CRow>
							<CRow  class="ml-1 mr-1">
							   <CCol lg="6">
				                  <strong style="font-size:15px">Total Rate </strong>
		    	               </CCol>
			                   <CCol lg="6" class="text-left">
			                      <strong style="font-size:15px" >{{dataParams.total_amount | number_format('currency')}}</strong>
			                   </CCol>
							</CRow>
							<CRow  class="ml-1 mr-1">
							   <CCol lg="6" v-if="dataParams.is_subcon == 1">
				                  <strong style="font-size:15px">Subcon Rate </strong>
		    	               </CCol>
			                   <CCol lg="6" class="text-left" v-if="dataParams.is_subcon == 1">
			                      <strong style="font-size:15px" >{{dataParams.subcon_rate | number_format('currency')}}</strong>
			                   </CCol>
							</CRow>
								
						</fieldset>
					</CCol>
				</CRow> -->
				
			<CRow class="mr-0 ml-0 pl-2 pr-2"> 
				<!-- Personnel Rates -->
			<CCol lg="6">
				<div class="table-responsive-sm table-responsive-md">
					<table class="table table-borderless table-sm text-left">
						<thead class="bg-light" style="font-weight:bold;">
							<tr>
								<td class="table-h" colspan="12">Personnel Rates</td>
							</tr>
						</thead>
						<tbody >
							<tr >
								<td class="table-pr-data" colspan="4" >Driver Rate</td>
								<td class="table-pr-data-2" colspan="2" style="font-weight:bold">{{(dataParams.driver_rate ? dataParams.driver_rate : '-') | number_format('currency') }}</td>
								<td class="table-pr-data" colspan="4" >Driver Allowance</td>
								<td class="table-pr-data-2" colspan="2" style="font-weight:bold">{{(dataParams.driver_allowance ? dataParams.driver_allowance : '-') | number_format('currency')}}</td>
							</tr>

							<tr >
								<td class="table-pr-data" colspan="4" >Helper 1 Rate</td>
								<td class="table-pr-data-2" colspan="2" style="font-weight:bold">{{(dataParams.helper_a_rate ? dataParams.helper_a_rate : '-') | number_format('currency')}}</td>
								<td class="table-pr-data" colspan="4" >Helper 1 Allowance</td>
								<td class="table-pr-data-2" colspan="2" style="font-weight:bold">{{(dataParams.helper_a_allowance ? dataParams.helper_a_allowance : '-') | number_format('currency')}}</td>
							</tr>
							<tr >
								<td class="table-pr-data" colspan="4" >Helper 2 Rate</td>
								<td class="table-pr-data-2" colspan="2" style="font-weight:bold">{{(dataParams.helper_b_allowance ? dataParams.helper_b_allowance : '-') | number_format('currency')}}</td>
								<td class="table-pr-data" colspan="4" >Helper 2 Allowance</td>
								<td class="table-pr-data-2" colspan="2" style="font-weight:bold">{{(dataParams.helper_b_allowance ? dataParams.helper_b_allowance : '-') | number_format('currency')}}</td>
							</tr>
						</tbody>	
					</table>

				</div>  
                      
            </CCol>
			<CCol lg="6">
				<div class="table-responsive-sm table-responsive-md">
					<table class="table table-borderless table-sm text-left">
						<thead class="bg-light" style="font-weight:bold;">
							<tr>
								<td class="table-h" colspan="12">Client Rates</td>
								
								
							</tr>
						</thead>
						<tbody >
							<tr >
								<td class="table-pr-data" colspan="4" >Client Rate</td>
								<td colspan="2" class="table-pr-data-2">{{(dataParams.client_rate ? dataParams.client_rate : '-') | number_format('currency') }}</td>
								
							</tr>

							<tr >
								<td class="table-pr-data" colspan="4" >No. of Trips</td>
								<td colspan="2" class="table-pr-data-2">{{(dataParams.no_of_trips ? dataParams.no_of_trips : '-')}}</td>
							
							</tr>
							<tr >
								<td class="table-rates-data" colspan="4" >Total Client Rate</td>
								<td class="table-rates-data" colspan="2">{{(dataParams.total_amount ? dataParams.total_amount : '-') | number_format('currency')}}</td>
							
							</tr>
							<tr v-if="dataParams.is_subcon == 1" >
								<td class="table-rates-data" colspan="4" >Subcon Rate</td>
								<td class="table-rates-data" colspan="2" >{{(dataParams.subcon_rate ? dataParams.subcon_rate : '-') | number_format('currency')}}</td>
							
							</tr>
							
							
							
							
						</tbody>	
					</table>

				</div>  
                      
            </CCol>
			
						
			</CRow> 
	
			</fieldset>
			
		
		

			</CCardBody> 
		</CCard>
		<HistoryModal :details="dataParams" @showModal="showHistoryModal = $event" :showModal="showHistoryModal" />
		<ApproveModal :details="dataParams" @showModal="approveModal = $event; reload();" :showModal="approveModal" />
        <DispatchModal :details="dataParams" @showModal="dispatchModal = $event; reload();" :showModal="dispatchModal" />
        <DeliverModal :details="dataParams" @showModal="deliverModal = $event; reload();" :showModal="deliverModal" />
        <CompleteModal :details="dataParams" @showModal="completeModal = $event; reload();" :showModal="completeModal" />
        <Print :details="dataParams" v-if="company.includes('Monocrete')||company.includes('monocrete')"/>
        <MarvelPrint :details="dataParams" v-if="company.includes('Marvel')||company.includes('marvel')"/>
		<RoadwisePrint :details="dataParams" v-if="company.includes('Roadwise')||company.includes('roadwise')"/>
		<ChargesModal :details="dataParams" @showModal="chargesModal = $event; reload();" :showModal="chargesModal" />
		<ExpensesModal :details="dataParams" @showModal="expensesModal = $event; reload();" @showloader="showLoader" :showModal="expensesModal" />
		<!-- <RoadwisePrint :details="dataParams" v-if="true"/> -->
	</div>
</template>
<script> 
import config from '../config.js';
import axios from '../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
// import 'vue-select/dist/vue-select.css';
import '../style.css';
import moment from 'moment';
import HistoryModal from './history_modal/index';
import ApproveModal from './status_modal/approve';
import DispatchModal from './status_modal/dispatch';
import DeliverModal from './status_modal/deliver';
import CompleteModal from './status_modal/complete';
import ChargesModal from './charges/index';
import Print from './print';
import MarvelPrint from './marvel_print';
import RoadwisePrint from './roadwise_print';
import Loader from '../../loader.mixin.js';
import ExpensesModal from './booking_expenses/index';

export default {
	mounted(){  

		this.getData();
		// this.getVendor();
		// this.getVehicleType();
		// this.getMakerModel();
		// this.getDepot();
		// this.getOrigin(); 

		this.company = null;
	    if(config.getUserDetail('company')){
	      this.company = config.getUserDetail('company').company_name;
	    }

	},
	data(){
		return{
			user_details: JSON.parse(window.localStorage.getItem('user_data')),
			company_type_withagency: JSON.parse(window.localStorage.getItem('user_data')).company.company_type,
			company: "",
			approveModal: false,
			chargesModal: false,
			dispatchModal: false,
			deliverModal: false,
			completeModal: false,
			formModal: false,
			showViewModal: false,
			showHistoryModal: false,
			expensesModal: false,
			dataList: [],
			// dataListInactive: [],
			depotName: "",
			company_type: JSON.parse(window.localStorage.getItem("user_data")).company.company_type.includes("Trucking") ? "trucking" : JSON.parse(window.localStorage.getItem("user_data")).company.company_type,
			dataParams: {
			// 	booking_no: "",

			// 	id: "",
			// 	commodity_id: "",
			// 	customer_id: "",
			// 	depot_id: "",
			// 	helper_a_id: "",
			// 	helper_b_id: "",	
			// 	vehicle_type_id: "",
			// 	maker_model_id: "",
			// 	origin_id: "",
			// 	vendor_id: "",
			// 	driver_id: "",
			// 	body_no: "",
			// 	year: "",
			// 	with_gps: "",
			// 	company_owned: true,
			// 	plate_no: "",
			// 	registration_date: "",
			// 	asset_no: "",
			// 	engine_no: "",
			// 	engine_size: "",
			// 	chassis_no: "",
			// 	transmission_type: "",
			// 	transfer_date: "",
			// 	color: "",
			// 	fuel_type: "",
			// 	later_renewal_date: "",
			// 	acquisition_date: "",
			// 	acquisition_price: "",
			// 	remarks: "",
			// 	vehicle_photo: "",
			// 	or_photo: "",
			// 	cr_photo: "",
			// 	insurance_photo: "",
			// 	status: "active", 
			// 	location: ""
			 },
			editMode: false, 
			// vendorList: {
			// 	data: []
			// },
			// vehicleTypeList: {
			// 	data: []
			// },
			// makerModelList: {
			// 	data: []
			// },
			// depotList: {
			// 	data: []
			// },
			// originList: {
			// 	data: []
			// },
			isLoading: false,
			uploadPercentage: 0,
			config,
			moment
		}
	},
	mixins: [Loader],
	name: 'Tables',
	components: {Search, Datepicker, vSelect, HistoryModal, ApproveModal, DispatchModal, DeliverModal, CompleteModal, Print, MarvelPrint, RoadwisePrint, ChargesModal,ExpensesModal},
	methods: { 
		showLoader(val) {
			this.isLoading = val;
		},
		reload(){
			setTimeout(()=>{
				this.getData();
			},500)
		},
		getData(){
			
			this.$showLoading(true)
			axios.get(config.api_path+"/booking/"+this.$route.params.id)
			.then(response=>{  
				this.$showLoading(false)
				this.dataParams = response.data.data; 
				if(this.dataParams.vehicle){
    				this.dataParams.plate_no = this.dataParams.vehicle.plate_no;
					if(this.dataParams.vehicle?.odometer?.length) {
						let odosort = this.dataParams.vehicle.odometer.sort((a, b) => a.date - b.date);
						this.dataParams.vehicle.odometer = [...odosort]
					}
    			}
    			else{
    				this.dataParams.plate_no = "-"
    			}
				this.dataParams.booking_history = this.dataParams.booking_history.map((value, index)=>{
					value.users_id = value.user.firstname+" "+value.user.lastname;
					if(value.remarks == null || value.remarks == ""){
						value.remarks = "-"
					}
					value.created_at = moment(value.created_at).format('MMM DD, YYYY hh:mm A') ;
					
					return value;
				})

				console.log(this.dataParams, 'PARAMSSS')
			})
			.catch(err => {
			this.$showLoading(false)
		}) 
    },

	    // getVendor(){

	    // 	axios.get(config.api_path+'/vendor?page=1&limit=10000000')
	    // 	.then(response => {
	    // 		this.vendorList = response.data;
	    // 		this.vendorList.data = this.vendorList.data.map((value, index)=>{
	    // 			value.value = value.id;
	    // 			value.label = value.vendor_name;
	    // 			return value;
	    // 		})
	    // 	})

	    // },

	    // getVehicleType(){

	    // 	axios.get(config.api_path+'/setting?setting_type=vehicle_type&page=1&limit=10000000')
	    // 	.then(response => {
	    // 		this.vehicleTypeList = response.data; 
	    // 	})

	    // },

	    // getMakerModel(){

	    // 	axios.get(config.api_path+'/setting?setting_type=maker_model&page=1&limit=10000000')
	    // 	.then(response => {
	    // 		this.makerModelList = response.data; 
	    // 	})

	    // },

	    // getDepot(){

	    // 	axios.get(config.api_path+'/setting?setting_type=depot&page=1&limit=10000000')
	    // 	.then(response => {
	    // 		this.depotList = response.data; 
	    // 	})

	    // },

	    // getOrigin(){

	    // 	axios.get(config.api_path+'/setting?setting_type=origin&page=1&limit=10000000')
	    // 	.then(response => {
	    // 		this.originList = response.data; 
	    // 	})

	    // },

	    updateCompanyOwned(e){
	    	this.dataParams.company_owned = e;
	    },

	    getStatus(status){

	    	if(status == "active"){return "Active";}
	    	if(status == "inactive"){return "Inactive";}
	    	if(status == "incoming"){return "Incoming";}
	    	if(status == "archive"){return "Archive/Sold";} 

	    },

	    transimission(trans){
	    	if(trans == "manual"){return "Manual";}
	    	if(trans == "automatic"){return "Automatic";}
	    },

	    fuelType(fuel){
	    	if(fuel == "gasoline"){return "Gasoline";}
	    	if(fuel == "diesel"){return "Diesel";}
	    },

	    backToTable(){
	    	this.$router.push('/main/booking')
	    },

	    openApproveModal(item){
	    	this.approveModal = true;
	    	this.selectedRow = item;
	    },

	    openChargesModal(item){
	    	this.chargesModal = true;
	    	this.selectedRow = item;
	    },
		
	    openExpensesModal(item){
	    	this.expensesModal = true;
	    	this.selectedRow = item;
	    },

	    openDispatchModal(item){
	    	this.dispatchModal = true;
	    	this.selectedRow = item;
	    },

	    openDeliverModal(item){
	    	this.deliverModal = true;
	    	this.selectedRow = item;
	    },

	    openCompleteModal(item){
	    	this.completeModal = true;
	    	this.selectedRow = item;
	    },

	    updateModal(item){
	    	this.$router.push('/main/update_booking/'+item.id)
	    },

	    viewDr(item){
	    	window.open(
			  '/#/booking/'+item.id+"/dr",
			  '_blank'
			); 
	    },

	    viewMts(item){
	    	window.open(
			  '/#/booking/'+item.id+"/mts",
			  '_blank'
			);  
	    },

	    print(){


	    	var mywindow = window.open('', 'PRINT');

		    mywindow.document.write('<html><head>');
		    mywindow.document.write('<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css" integrity="sha384-KyZXEAg3QhqLMpG8r+8fhAXLRk2vvoC2f3B09zVXn8CA5QIVfZOJ3BCsw2P0p/We" crossorigin="anonymous">')
		    mywindow.document.write('<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css">')
		    mywindow.document.write('</head><body >'); 
		    mywindow.document.write(document.getElementById('booking_print').innerHTML);
		    mywindow.document.write('</body></html>');

		    mywindow.document.close(); // necessary for IE >= 10
		    mywindow.focus(); // necessary for IE >= 10*/

		    mywindow.print();
		    mywindow.close();
	    },

 	}
}
</script>
<style scoped>
.form-group{
	margin-bottom: 0px !important;
}
.table-h{
	font-size: 11px ! important;
	color:#3c4b64 !important;
    
}
.table-pr-data{
	color:#3c4b64 !important;
	font-size: 11px ! important;
}
.table-pr-data-2{
	color:#3c4b64 !important;
	font-size: 13px ! important;
}
.table-rates-data{
	font-size:13px!important;
	font-weight:bold!important;
	color:#3c4b64 !important;
}
	

</style>