<template>
<div v-if="user_details.company != null">
    <div v-if="user_details.company.company_type.includes('Trucking')">
       
<fieldset class="fieldset full-line">
				<legend >Expenses</legend>
    <fieldset class="fieldset broken-line">
				<legend >Billable</legend>
        <CRow>
           
            <CCol lg="6">
                <label class="mb-1">Toll Fees</label>
                <CInput
                    @focus="$event.target.select()"
                    @paste="onPaste($event, 'bookingParams','billable','toll_fee')"
                    :class="{'has-error' : error_list['toll-fee']}"
                        placeholder="0.00"
                        v-model="bookingParams.billable.toll_fee" 
                       @keypress="isNumber($event)"
                        
                    />
                    <small v-if="error_list['toll-fee']" class="text-danger" style="font-size:10px">{{ error_list['toll-fee'] }}</small>
            </CCol> 
            <CCol lg="6">
                <label class="mb-1">Extra Drop</label>
            <CInput
            @paste="onPaste($event, 'bookingParams','billable','extra_drop')"
            @focus="$event.target.select()"
                    placeholder="0.00"
                    v-model="bookingParams.billable.extra_drop" 
                    @keypress="isNumber($event)"
                    
                />
        </CCol>
        <CCol lg="6">
            <label class="mb-1">Extra Helper</label>
            <CInput
            @paste="onPaste($event, 'bookingParams','billable','extra_helper')"
            @focus="$event.target.select()"
                    placeholder="0.00"
                    v-model="bookingParams.billable.extra_helper" 
                    @keypress="isNumber($event)"
                />
        </CCol>
        <CCol lg="6">
            <label class="mb-1">Other Expenses/Fees</label>
                <CInput
                @paste="onPaste($event, 'bookingParams','billable','other_expenses')"
                @focus="$event.target.select()"
                        placeholder="0.00"
                        v-model="bookingParams.billable.other_expenses" 
                        @keypress="isNumber($event)"
                    />
            </CCol>   
        </CRow> 
       
    </fieldset>
     <fieldset class="fieldset broken-line">
				<legend >Non-Billable</legend>
        <CRow class="mb-2">
            <CCol lg="6">
                <label class="mb-1">Parking Fees</label>
                <CInput
                @paste="onPaste($event, 'bookingParams','billable','parking_fee')"
                    @focus="$event.target.select()"
                    :class="{'has-error' : error_list['parking-fee']}"
                        placeholder="0.00"
                        v-model="bookingParams.billable.parking_fee" 
                        @keypress="isNumber($event)"
                        
                    />
                    <small v-if="error_list['parking-fee']" class="text-danger" style="font-size:10px">{{ error_list['parking-fee'] }}</small>
            </CCol> 
            <CCol lg="6">
                <label class="mb-1">Toll Fees-Non Billable</label>
                <CInput
                        @paste="onPaste($event, 'bookingParams','billable','toll_fee_non_billable')"
                        @focus="$event.target.select()"
                        placeholder="0.00"
                        v-model="bookingParams.billable.toll_fee_non_billable" 
                       @keypress="isNumber($event)"
                        
                    />
            </CCol> 
            <CCol lg="6">
            <label class="mb-1">Demurrage Fees</label>
            <CInput
            @paste="onPaste($event, 'bookingParams','billable','demurrage_fee')"
            @focus="$event.target.select()"
                    placeholder="0.00"
                    v-model="bookingParams.billable.demurrage_fee" 
                    @keypress="isNumber($event)"
                    
                />
        </CCol>
        <CCol lg="6">
            <label class="mb-1">Backload Fees</label>
            <CInput
            @paste="onPaste($event, 'bookingParams','billable','backload_fee')"
            @focus="$event.target.select()"
                    placeholder="0.00"
                    v-model="bookingParams.billable.backload_fee" 
                    @keypress="isNumber($event)"
                />
        </CCol> 
        </CRow> 
    </fieldset>
</fieldset>
    </div>
</div> 
</template>
<script>
    export default {
        name : 'Expenses',
        props: ['bookingParams','user_details','error_list'],
        methods: {
			onPaste(evt, parent, object, key) {
				let prev = evt.target.value;
				let value = evt.clipboardData.getData('text');
				
				let clean = value?.replace(/,/g, "") ?? '';

				if(isNaN(clean)) {
					alert('Invalid number value.')
					evt.preventDefault();
					evt.target.value = prev;
					if(object) {
						this[parent][object][key] = prev;
					}
					else this[parent][key] = prev;
					return true;
				}
				evt.preventDefault()
				evt.target.value = clean;
				if(object)
					this.$set(this[parent][object], key, clean)
				else this.$set(this[parent], key, clean)

				console.log(this[parent][object][key])
				return true;
				
			},
        }
        
    }

</script>