<!-- ORIGINAL PRINT --->
 <!-- <template>

 <div class="content-container print"  id="booking_print" style="background-color: white; ">

<table>
			<tr>
				<td style="letter-spacing: 2px;" width="500">
					{{details.customer ? details.customer.customer_name : "N/A"}}
				</td>
				<td style="letter-spacing: 2px;" width="500">
				{{details.booking_type ? details.booking_type.setting_name : "N/A"}}
				</td> 
				<td style="letter-spacing: 2px;" width="300">
				{{details.delivery_date? moment.unix(details.delivery_date).format('MMMM DD, YYYY') : "N/A"}}
				</td>
				<td style="letter-spacing: 2px;" width="50">
					{{details.booking_no ? details.booking_no : "N/A"}}
				</td>
			
			</tr>
			<tr>
				<td style="letter-spacing: 2px;" width="400">
					{{details.origin ? details.origin.setting_name : "N/A"}}
				</td> 
				<td style="letter-spacing: 2px;" width="400">
				{{details.commodity ? details.commodity.setting_name : "N/A"}}
				</td>
				
			</tr>
				<tr>
				<td style="letter-spacing: 2px;" width="400">
					Company Owned
				</td> 
				<td style="letter-spacing: 2px;" width="400">
					{{details.vehicle ? details.vehicle.plate_no : "N/A"}}
				</td>
				<td style="letter-spacing: 2px;" width="400">
					{{details.vehicle_type ? details.vehicle_type.setting_name : "N/A"}}
				</td>
				
				
			</tr>
			<tr>
				<td style="letter-spacing: 2px;" width="400">
					{{details.driver ? details.driver.name : "N/A"}}
				</td> 
				<td style="letter-spacing: 2px;" width="400">
					{{details.helper_a ? details.helper_a.name : "N/A"}}
				</td>
				
			</tr>
		</table>
			<table
			style="margin-top: 200px;"
		>
			<tr>
				<td width="400" style="text-align: center; letter-spacing: 2px;">Crista Pangilinan</td>
				<td width="400" style="text-align: center; letter-spacing: 2px;">{{details.driver ? details.driver.name : "N/A"}}</td>
				<td width="400" style="text-align: center; letter-spacing: 2px;">Michiko Ashley Sato</td>
				<td width="400" ></td>
			</tr>
			
		</table>


	</div> 

</template>

<style>
@media print {
.printme {
        position: absolute;

    }
}

</style>
<script>     
	import moment from 'moment';
	export default{

		props: { 
			details: {
				booking_no: "",



				equipment: "",
				vehicle_type_id: "",
				maker_model_id: "",
				origin_id: "",
				vendor_id: "",
				driver_id: "",
				body_no: "",
				year: "",
				with_gps: "",
				company_owned: true,
				plate_no: "",
				registration_date: "",
				asset_no: "",
				engine_no: "",
				engine_size: "",
				chassis_no: "",
				transmission_type: "",
				transfer_date: "",
				color: "",
				fuel_type: "",
				later_renewal_date: "",
				acquisition_date: "",
				acquisition_price: "",
				remarks: "",
				vehicle_photo: "",
				or_photo: "",
				cr_photo: "",
				insurance_photo: "",
				status: "active", 
				location: ""
			}
		},

		data(){
			return{
				moment
			}
		}

	}
</script>
-->
<template>
	<div class="content-container print" style="overflow: hidden; margin-top: -20px" id="booking_print"> 
		<div>
			<table style="width: 100%; margin-bottom:3px">
				<tr>
					<td>
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 9px">888 Pedro Cayetano Ave., C-5 Extension</div>
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 9px">Diversion Road, Palingon -Tipas</div> 		
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 9px">Taguig City 1673</div> 
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 9px"><i class="fa fa-phone"></i> (02) 628-8228</div> 			
					</td>
					<td>				
					</td>

					<td style="text-align: right">
						 <img src="/Monocrete-New-Logo.png" style="height: 60px; width:50%" />
					</td>
				</tr>
				<tr>
					<td style="text-align: left">
						
					</td>
					<td style="text-align: center">
						<span style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-weight: bold">
							<span style="font-size: 20px">Dispatch Release Form &nbsp;</span>

						</span>
					</td>
					<td style="text-align: right">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 14px; font-weight: bold">
							WB No. {{details.booking_no ? details.booking_no : "N/A"}}</div> 
						
					</td>
				</tr>
			</table>
			
			<table style="width: 100%; font-size: 11px">
				<tr>
					<td style="padding-right: 7px">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">
							<span> Customer <b>{{details.customer ? details.customer.customer_name : "N/A"}}</b></span> </div>
					</td>
					<td style="vertical-align: text-top; padding-left: 7px; padding-right: 7px; width: 31%">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">
							<span> Type <b>{{details.booking_type ? details.booking_type.setting_name : "N/A"}}</b></span> </div>
					</td>
					<td style="vertical-align: text-top; padding-left: 7px; width: 31%">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">
							<span> Delivery Date <b>{{details.delivery_date? moment.unix(details.delivery_date).format('MMMM DD, YYYY') : "N/A"}}</b></span> </div>
						
					</td>
					
				</tr>
				<tr>
					<td style="padding-right: 7px"> 
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">
							<span>Origin <b>{{details.origin ? details.origin.setting_name : "N/A"}}</b></span> </div>
	
					</td>
					<td style="vertical-align: text-top; padding-left: 7px; padding-right: 7px">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">
							<span>Commodity Type <b>{{details.commodity ? details.commodity.setting_name : "N/A"}}</b></span> </div>
					</td>
					<td style="vertical-align: text-top; padding-left: 7px">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">
							<span>Fuel Po. <b>{{details.fuel_po ? details.fuel_po : "N/A"}}</b></span> </div>
					</td>
				</tr>
				
				<tr>
					<td style="padding-right: 7px"> 
					<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">
							<span>Destination <b>{{details.destination ? details.destination.setting_name : "N/A"}} </b></span> </div>
	
					</td>
					<td style="vertical-align: text-top; padding-left: 7px; padding-right: 7px">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">
								<span>Vehicle <b>{{details.vehicle ? details.vehicle.plate_no : "N/A"}} </b></span> </div>
						
					</td>
					<td style="vertical-align: text-top; padding-left: 7px; padding-right: 7px">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">
							<span> Truck Type <b>{{details.vehicle_type ? details.vehicle_type.setting_name  : "N/A"}} </b></span> </div>
					</td>
				</tr>
				<tr>
					<td style="padding-right: 7px"> 
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">
						<span> Driver <b>{{details.driver ? details.driver.name : "N/A"}} </b></span> </div>
					</td>	
					<td style="vertical-align: text-top; padding-left: 7px; padding-right: 7px">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">
						<span> Helper 1 <b>{{details.helper_a ? details.helper_a.name : "N/A"}} </b></span> </div>
						
					</td>
					<td style="vertical-align: text-top; padding-left: 7px; padding-right: 7px">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">
						<span> Helper 2 <b>{{details.helper_b ? details.helper_b.name : "N/A"}} </b></span> </div>
						
					</td>
					
				</tr>
				<tr>
					<td style="padding-right: 7px" colspan="3"> 
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">
							<span> Remarks <b>{{(details.remarks && details.remarks != "") ? details.remarks : "N/A"}}</b></span> </div>
					</td> 
				</tr>
				<tr>
					<td style="padding-right: 7px; vertical-align: top; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif" colspan="3" > 
						<table style="border: 1px solid black ; width: 100%; font-size: 11px; margin-top: 0px">
							<tr style="border: 1px solid black">	
								<td style="width:40%; border-bottom: 1px solid black; border-right: 1px solid black; text-align: center; font-weight: bold" >ROUTE</td>
								<td style="width:10%;border-bottom: 1px solid black; border-right: 1px solid black; text-align: center; font-weight: bold">DR #</td>
								<td style="border-bottom: 1px solid black; border-right: 1px solid black; text-align: center; font-weight: bold">Time In</td>
								<td style="border-bottom: 1px solid black; border-right: 1px solid black; text-align: center; font-weight: bold">Time Out</td>
								<td style="border-bottom: 1px solid black; border-right: 1px solid black; text-align: center;">
								<span> <b>Received by</b> <br> <small class="font-w300">(Complete Name & Signature)</small></span></td>
								<td style="border-bottom: 1px solid black; border-right: 1px solid black; text-align: center; font-weight: bold">Remarks</td>
							</tr>
							<tr style="border: 1px solid black; height: 20px" v-for="index in 10" :key="index">	
								<td style="border-bottom: 1px solid black; border-right: 1px solid black;">&nbsp;</td>
								<td style="border-bottom: 1px solid black; border-right: 1px solid black;">&nbsp;</td>
								<td style="border-bottom: 1px solid black; border-right: 1px solid black;">&nbsp;</td>
								<td style="border-bottom: 1px solid black; border-right: 1px solid black;">&nbsp;</td>
								<td style="border-bottom: 1px solid black; border-right: 1px solid black;">&nbsp;</td>
								<td style="border-bottom: 1px solid black; border-right: 1px solid black;">&nbsp;</td>
							</tr>
								
						</table>
					</td>
					 
				</tr>
			</table>
			<table style="width: 100%; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 11px">
				<tr>	
					<td style="vertical-align: text-top; padding-left: 7px">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Prepared by:</div>
						<br>
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-left:40px; padding-bottom: 7px; margin-top:-7px">{{details.created_by ? details.created_by.firstname+" "+details.created_by.lastname : "N/A"}}</div> 
						<div style="font-size: 9px; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-left:40px; padding-bottom: 5px; margin-top:-5px">Coordinator</div>
					</td>
					<td style="vertical-align: text-top; padding-left: 7px">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Acknowledged by:</div>
						<br>
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-left:40px; padding-bottom: 7px; margin-top:-7px">{{details.driver ? details.driver.name : "N/A"}}</div>
						<div style="font-size: 9px; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-left:40px; padding-bottom: 5px; margin-top:-5px">Driver</div>
					</td>
					<td style="vertical-align: text-top; padding-left: 7px">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Approved by:</div>
						<br>
						<!-- <div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-left:40px; padding-bottom: 7px; margin-top:-7px">{{details.customer ? details.customer.contact_person : "N/A"}}</div> -->
					
					</td>
				</tr>
				<tr>
					<td colspan="2"></td>
					<td style="text-align:right">
						<small>
							FM-MNT-031, Rev. 00
						</small>
					</td>
				</tr>
			</table>
			<!-- <hr v-if="index== 1"> -->
		</div>
		
	</div>

</template>

<style scoped>
@page { size: auto;  margin: 0mm; }
.test{
	color: blue
}
	@media print {
      .col-special-1, .col-special-2, .col-special-3, .col-special-4, .col-special-5, .col-special-6,
      .col-special-7, .col-special-8, .col-special-9, .col-special-10, .col-special-11, .col-special-12 {
           float: left;               
      }

      .col-special-12 {
           width: 100%;
      }

      .col-special-11 {
           width: 91.66666666666666%;
      }

      .col-special-10 {
           width: 83.33333333333334%;
      }

      .col-special-9 {
            width: 75%;
      }

      .col-special-8 {
            width: 66.66666666666666%;
      }

       .col-special-7 {
            width: 58.333333333333336%;
       }

       .col-special-6 {
            width: 50%;
       }

       .col-special-5 {
            width: 41.66666666666667%;
       }

       .col-special-4 {
            width: 33.33333333333333%;
       }

       .col-special-3 {
            width: 25%;
       }

       .col-special-2 {
              width: 16.666666666666664%;
       }

       .col-special-1 {
              width: 8.333333333333332%;
        }     
}
</style>

<script>     
	import moment from 'moment';
	export default{

		props: { 
			details: {
				booking_no: "",



				equipment: "",
				vehicle_type_id: "",
				maker_model_id: "",
				origin_id: "",
				vendor_id: "",
				driver_id: "",
				body_no: "",
				year: "",
				with_gps: "",
				company_owned: true,
				plate_no: "",
				registration_date: "",
				asset_no: "",
				engine_no: "",
				engine_size: "",
				chassis_no: "",
				transmission_type: "",
				transfer_date: "",
				color: "",
				fuel_type: "",
				later_renewal_date: "",
				acquisition_date: "",
				acquisition_price: "",
				remarks: "",
				vehicle_photo: "",
				or_photo: "",
				cr_photo: "",
				insurance_photo: "",
				status: "active", 
				location: ""
			}
		},

		data(){
			return{
				moment
			}
		}

	}
</script>
