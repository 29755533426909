<template>
	<div class="content-container print" style="overflow: hidden; margin-top: -50px" id="booking_print"> 
		<div class="" v-for="index of 2" :key="index">
			<table style="width: 100%">
				<tr>
					<td>
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 20px; font-weight: bold">
							<i class="fa fa-send"></i> ROADWISE LOGISTICS CORP</div>
						<!-- <div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 9px">Marvel Trucking Solutions Inc</div>
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 9px">Mercedes Business Park, Mercedes Ave, Rizal</div>
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 9px">BIR-TIN: 009-788-189-000</div> -->
					</td>
					<td style="vertical-align: text-top; text-align: right">
						<span style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-weight: bold">
							<span style="font-size: 20px">WB No. &nbsp;</span>
							<span style="font-size: 14px">{{details.booking_no ? details.booking_no : "N/A"}}</span>
						</span>
					</td>
				</tr>
			</table>
			<br/>
			<table style="width: 100%; font-size: 11px">
				<tr>
					
					<td style="padding-right: 7px; width: 31%"> 
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Customer</div>
						<div style="font-weight:600; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px; margin-top:-7px">{{details.customer ? details.customer.customer_name : "N/A"}}</div> 
					</td>
					<td style="vertical-align: text-top; padding-left: 7px; padding-right: 7px; width: 31%">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Type</div>
						<div style="font-weight:600; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px; margin-top:-7px">{{details.booking_type ? details.booking_type.setting_name : "N/A"}}</div> 
					</td>
					<td style="vertical-align: text-top; padding-left: 7px; width: 31%">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Delivery Date</div>
						<div style="font-weight:600; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;  padding-bottom: 7px; margin-top:-7px">{{details.delivery_date? moment.unix(details.delivery_date).format('MMMM DD, YYYY') : "N/A"}}</div> 
					</td>
				</tr>
				<tr>
					<td style="padding-right: 7px"> 
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Origin</div>
						<div style="font-weight:600;  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px; margin-top:-7px">{{details.origin ? details.origin.setting_name : "N/A"}}</div> 
					</td>
					<td style="vertical-align: text-top; padding-left: 7px; padding-right: 7px">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Commodity Type</div>
						<div style="font-weight:600; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;  padding-bottom: 7px; margin-top:-7px">{{details.commodity ? details.commodity.setting_name : "N/A"}}</div> 
					</td>
					
					<td style="vertical-align: text-top; padding-left: 7px">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Fuel PO</div>
						<div style="font-weight:600; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;  padding-bottom: 7px; margin-top:-7px">{{details.fuel_po ? details.fuel_po : "N/A"}}</div> 
					</td>
				</tr>
				<tr>
					<td style="padding-right: 7px"> 
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Vendor</div>
						<div style="font-weight:600; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;  padding-bottom: 7px; margin-top:-7px">
						{{details.is_subcon == 0 ? details.company.company_name : (details.vendor) ? details.vendor.vendor_name: ''}}</div> 
					
					
					</td>
					<td style="vertical-align: text-top; padding-left: 7px; padding-right: 7px">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Vehicle</div>
						<div style="font-weight:600;  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;  padding-bottom: 7px; margin-top:-7px">{{details.vehicle ? details.vehicle.plate_no : "N/A"}}</div> 
					</td>
					<td style="vertical-align: text-top; padding-left: 7px; padding-right: 7px">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Truck Type</div>
						<div style="font-weight:600;  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;  padding-bottom: 7px; margin-top:-7px">{{details.vehicle_type ? details.vehicle_type.setting_name  : "N/A"}}</div> 
					</td>
				</tr>
				<tr>
					<td style="padding-right: 7px"> 
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Driver</div>
						<div style="font-weight:600; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px; margin-top:-7px">{{details.driver ? details.driver.name : "N/A"}}</div> 
					</td>
					<td style="vertical-align: text-top; padding-left: 7px; padding-right: 7px">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Helper 1</div>
						<div style="font-weight:600;  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;  padding-bottom: 7px; margin-top:-7px">{{details.helper_a ? details.helper_a.name : "N/A"}}</div> 
					</td>
					<td style="vertical-align: text-top; padding-left: 7px">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Helper 2</div>
						<div style="font-weight:600; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;  padding-bottom: 7px; margin-top:-7px">{{details.helper_b ? details.helper_b.name : "N/A"}}</div> 
					</td>
				</tr>
				<tr>
					<td style="padding-right: 7px" colspan="3"> 
						<div style=" font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Remarks: 
							<span style="font-weight:600;"> {{(details.remarks && details.remarks != "") ? details.remarks : "N/A"}}</span></div> 
					</td> 
				</tr>
				<tr>
					<td style="padding-right: 7px; vertical-align: top; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif" colspan="3" > 
						<table style="border: 1px solid black;  width: 100%; font-size: 11px; margin-top: 7px">
							<tr style="border: 1px solid black ">	
								<td style="width:40%; border-bottom: 1px solid  black ; border-right: 1px solid  black ; text-align: center; font-weight: bold" >ROUTE</td>
								<td style="width:10%;border-bottom: 1px solid  black ; border-right: 1px solid  black ; text-align: center; font-weight: bold">DR #</td>
								<td style="border-bottom: 1px solid  black ; border-right: 1px solid  black ; text-align: center; font-weight: bold">Time In</td>
								<td style="border-bottom: 1px solid  black ; border-right: 1px solid  black ; text-align: center; font-weight: bold">Time Out</td>
								<td style="border-bottom: 1px solid  black ; border-right: 1px solid  black ; text-align: center; font-weight: bold">Received By</td>
								<td style="border-bottom: 1px solid  black; border-right: 1px solid  black ; text-align: center; font-weight: bold">Signature</td>
							</tr>
							<tr style="border: 1px solid  black" v-for="index in 9" :key="index">	
								<td  style="border-bottom: 1px solid  black; border-right: 1px solid  black;">&nbsp;</td>
								<td style="border-bottom: 1px solid  black; border-right: 1px solid  black;">&nbsp;</td>
								<td style="border-bottom: 1px solid  black; border-right: 1px solid  black;">&nbsp;</td>
								<td style="border-bottom: 1px solid  black; border-right: 1px solid  black;">&nbsp;</td>
								<td style="border-bottom: 1px solid  black; border-right: 1px solid  black;">&nbsp;</td>
								<td style="border-bottom: 1px solid  black; border-right: 1px solid  black;">&nbsp;</td>
							</tr>
								
						</table>
					</td>
					 
				</tr>
			</table>
			<table style="width: 100%; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 11px">
				<tr>	
					<td style="vertical-align: text-top; padding-left: 7px">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Prepared By:</div>
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-left:10px; padding-bottom: 7px; margin-top:-7px">{{details.dispatched_by ? details.dispatched_by.firstname+" "+details.dispatched_by.lastname : "N/A"}}</div> 
						<div style="font-size: 9px; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-left:10px; padding-bottom: 5px; margin-top:-5px">Dispatcher</div>
					</td>
					<td style="vertical-align: text-top; padding-left: 7px">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Acknowledged By:</div>
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-left:10px; padding-bottom: 7px; margin-top:-7px">{{details.driver ? details.driver.name : "N/A"}}</div>
						<div style="font-size: 9px; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-left:10px; padding-bottom: 5px; margin-top:-5px">Driver</div>
					</td>
					
				
				</tr>
				<br>
				<tr>
					<td style="vertical-align: text-top; padding-left: 7px">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Cash Received:</div>
						<!-- <div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-left:10px; padding-bottom: 7px; margin-top:-7px">{{details.helper_b ? details.helper_b.name : "N/A"}}</div>  -->
					</td>
					<td style="vertical-align: text-top; padding-left: 7px">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Cash Received by:</div>
						<!-- <div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-left:10px; padding-bottom: 7px; margin-top:-7px">{{details.helper_b ? details.helper_b.name : "N/A"}}</div>  -->
					</td>
				</tr>
			</table>
			
			<hr v-if="index== 1">
		</div>
		
	</div>

</template>

<style scoped>
@page { size: auto;  margin: 0mm; }
.test{
	color: blue
}
	@media print {
      .col-special-1, .col-special-2, .col-special-3, .col-special-4, .col-special-5, .col-special-6,
      .col-special-7, .col-special-8, .col-special-9, .col-special-10, .col-special-11, .col-special-12 {
           float: left;               
      }

      .col-special-12 {
           width: 100%;
      }

      .col-special-11 {
           width: 91.66666666666666%;
      }

      .col-special-10 {
           width: 83.33333333333334%;
      }

      .col-special-9 {
            width: 75%;
      }

      .col-special-8 {
            width: 66.66666666666666%;
      }

       .col-special-7 {
            width: 58.333333333333336%;
       }

       .col-special-6 {
            width: 50%;
       }

       .col-special-5 {
            width: 41.66666666666667%;
       }

       .col-special-4 {
            width: 33.33333333333333%;
       }

       .col-special-3 {
            width: 25%;
       }

       .col-special-2 {
              width: 16.666666666666664%;
       }

       .col-special-1 {
              width: 8.333333333333332%;
        }     
}
</style>

<script>     
	import moment from 'moment';
	export default{

		props: { 
			details: {
				booking_no: "",



				equipment: "",
				vehicle_type_id: "",
				maker_model_id: "",
				origin_id: "",
				vendor_id: "",
				driver_id: "",
				body_no: "",
				year: "",
				with_gps: "",
				company_owned: true,
				plate_no: "",
				registration_date: "",
				asset_no: "",
				engine_no: "",
				engine_size: "",
				chassis_no: "",
				transmission_type: "",
				transfer_date: "",
				color: "",
				fuel_type: "",
				later_renewal_date: "",
				acquisition_date: "",
				acquisition_price: "",
				remarks: "",
				vehicle_photo: "",
				or_photo: "",
				cr_photo: "",
				insurance_photo: "",
				status: "active", 
				location: ""
			}
		},

		data(){
			return{
				moment
			}
		}

	}
</script>
