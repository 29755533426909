
export default {
    data() {
        return {
            error_list : {},
            user_details: JSON.parse(window.localStorage.getItem('user_data')),
        }
    },

    computed: {
        hasError() {
            return Object.keys(this.error_list).length;
        }
    },
    watch: {
        error_list : {
            handler: function(val) {
                console.log(val)
            },
            deep: true
        },
        'bookingParams.fuel': function(val) {
            if(this.user_details.company.company_name.toLowerCase().includes('marvel')) {
                let amount = val && !isNaN(val) && val.length ? parseFloat(val) : 0;
                if(amount > 80)
                    this.$set(this.error_list,'fuel', 'Fuel should not exceed 80 Liters');
                else
                    this.$delete(this.error_list, 'fuel');
            }
        },
       
        'bookingParams.fuel_amount': function(val) {
            if(this.user_details.company.company_name.toLowerCase().includes('marvel')) {
                let amount = val && !isNaN(val) && val.length ? parseFloat(val) : 0;
                if(amount > 6000)
                    this.$set(this.error_list,'fuel-amount', 'Fuel Amount should not exceed 6,000');
                else
                    this.$delete(this.error_list, 'fuel-amount');
            }
        },
        
        'bookingParams.billable.parking_fee': function(val) {
            if(this.user_details.company.company_name.toLowerCase().includes('marvel')) {
                let amount = val && !isNaN(val) && val.length ? parseFloat(val) : 0;
                if(amount > 1000)
                    this.$set(this.error_list,'parking-fee', 'Parking Fee should not exceed 1,000');
                else
                    this.$delete(this.error_list,'parking-fee');

                console.log(this.error_list, 'ERR LIST', amount)
            }
        },
        'bookingParams.billable.toll_fee': function(val) {
            if(this.user_details.company.company_name.toLowerCase().includes('marvel')) {
                let amount = val && !isNaN(val) && val.length ? parseFloat(val) : 0;
                if(amount > 5000)
                    this.$set(this.error_list,'toll-fee', 'Toll Fee should not exceed 5,000');
                else
                    this.$delete(this.error_list, 'toll-fee');
            }
        },
        'bookingParams.driver_allowance': function(val) {
            if(this.user_details.company.company_name.toLowerCase().includes('marvel')) {
                let amount = val && !isNaN(val) && val.length ? parseFloat(val) : 0;
                if(amount > 3000)
                    this.$set(this.error_list,'driver-allowance', 'Driver allowance should not exceed 3,000');
                else
                    this.$delete(this.error_list, 'driver-allowance');
            }
        },
        'bookingParams.helper_a_allowance': function(val) {
            if(this.user_details.company.company_name.toLowerCase().includes('marvel')) {
                let amount = val && !isNaN(val) && val.length ? parseFloat(val) : 0;
                if(amount > 2000)
                    this.$set(this.error_list,'helper1-allowance', 'Helper allowance should not exceed 2,000');
                else
                    this.$delete(this.error_list, 'helper1-allowance');
            }
        },
        'bookingParams.helper_b_allowance': function(val) {
            if(this.user_details.company.company_name.toLowerCase().includes('marvel')) {
                let amount = val && !isNaN(val) && val.length ? parseFloat(val) : 0;
                if(amount > 2000)
                    this.$set(this.error_list,'helper2-allowance', 'Helper allowance should not exceed 2,000');
                else
                    this.$delete(this.error_list, 'helper2-allowance');
            }
        },
        'bookingParams.driver_rate': function(val) {
            if(this.user_details.company.company_name.toLowerCase().includes('marvel')) {
                let amount = val && !isNaN(val) && val.length ? parseFloat(val) : 0;
                if(amount <= 0)
                    this.$set(this.error_list,'driver-rate', 'Driver rate must be greater than 0');
                else
                    this.$delete(this.error_list, 'driver-rate');
            }
        },
        'bookingParams.helper_a_rate': function(val) {
            if(this.user_details.company.company_name.toLowerCase().includes('marvel')) {
                let amount = val && !isNaN(val) && val.length ? parseFloat(val) : 0;
                if(amount <= 0)
                    this.$set(this.error_list,'helper1-rate', 'Helper rate must be greater than 0');
                else
                    this.$delete(this.error_list, 'helper1-rate');
            }
        },
        'bookingParams.helper_b_rate': function(val) {
            if(this.user_details.company.company_name.toLowerCase().includes('marvel')) {
                let amount = val && !isNaN(val) && val.length ? parseFloat(val) : 0;
                if(amount <= 0)
                    this.$set(this.error_list,'helper2-rate', 'Helper rate must be greater than 0');
                else
                    this.$delete(this.error_list, 'helper2-rate');
            }
        },

    }
} 
