<template>
	<div class="content-container print" style="overflow: hidden; margin-top: -50px" id="booking_print"> 
		<div class="" ng-repeat="page in print">
			<table style="width: 100%">
				<tr>
					<td>
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 20px; font-weight: bold">MARVEL TRUCKING</div>
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 9px">Marvel Trucking Solutions Inc</div>
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 9px">Mercedes Business Park, Mercedes Ave, Rizal</div>
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 9px">BIR-TIN: 009-788-189-000</div>
					</td>
					<td style="vertical-align: text-top; text-align: right">
						<span style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-weight: bold">
							<span style="font-size: 20px">WB No. &nbsp;</span>
							<span style="font-size: 14px">{{details.booking_no ? details.booking_no : "N/A"}}</span>
						</span>
					</td>
				</tr>
			</table>
			<br/>
			<table style="width: 100%; font-size: 11px">
				<tr>
					<td style="padding-right: 7px; width: 31%"> 
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Customer</div>
						<div style="border-bottom: 1px solid black;  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-left:10px; padding-bottom: 7px; margin-top:-7px">{{details.customer ? details.customer.customer_name : "N/A"}}</div> 
					</td>
					<td style="vertical-align: text-top; padding-left: 7px; padding-right: 7px; width: 31%">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Type</div>
						<div style="border-bottom: 1px solid black;  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-left:10px; padding-bottom: 7px; margin-top:-7px">{{details.booking_type ? details.booking_type.setting_name : "N/A"}}</div> 
					</td>
					<td style="vertical-align: text-top; padding-left: 7px; width: 31%">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Delivery Date</div>
						<div style="border-bottom: 1px solid black; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-left:10px; padding-bottom: 7px; margin-top:-7px">{{details.delivery_date? moment.unix(details.delivery_date).format('MMMM DD, YYYY') : "N/A"}}</div> 
					</td>
				</tr>
				<tr>
					<td style="padding-right: 7px"> 
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Origin</div>
						<div style="border-bottom: 1px solid black;  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-left:10px; padding-bottom: 7px; margin-top:-7px">{{details.origin ? details.origin.setting_name : "N/A"}}</div> 
					</td>
					<td style="vertical-align: text-top; padding-left: 7px; padding-right: 7px">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Vehicle</div>
						<div style="border-bottom: 1px solid black;  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-left:10px; padding-bottom: 7px; margin-top:-7px">{{details.vehicle ? details.vehicle.plate_no : "N/A"}}</div> 
					</td>
					<td style="vertical-align: text-top; padding-left: 7px">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Fuel PO</div>
						<div style="border-bottom: 1px solid black; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-left:10px; padding-bottom: 7px; margin-top:-7px">{{details.fuel_po ? details.fuel_po : "N/A"}}</div> 
					</td>
				</tr>
				<tr>
					<td style="padding-right: 7px"> 
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Driver</div>
						<div style="border-bottom: 1px solid black; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-left:10px; padding-bottom: 7px; margin-top:-7px">{{details.driver ? details.driver.name : "N/A"}}</div> 
					</td>
					<td style="vertical-align: text-top; padding-left: 7px; padding-right: 7px">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Helper 1</div>
						<div style="border-bottom: 1px solid black;  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-left:10px; padding-bottom: 7px; margin-top:-7px">{{details.helper_a ? details.helper_a.name : "N/A"}}</div> 
					</td>
					<td style="vertical-align: text-top; padding-left: 7px">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Helper 2</div>
						<div style="border-bottom: 1px solid black; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-left:10px; padding-bottom: 7px; margin-top:-7px">{{details.helper_b ? details.helper_b.name : "N/A"}}</div> 
					</td>
				</tr>
				<tr>
					<td style="padding-right: 7px" colspan="3"> 
						<div style="border-bottom: 1px solid black; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Remark: {{(details.remarks && details.remarks != "") ? details.remarks : "N/A"}}</div> 
					</td> 
				</tr>
				<tr>
					<td style="padding-right: 7px; vertical-align: top; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif" colspan="2" > 
						<table style="border: 1px solid #ddd; width: 100%; font-size: 11px; margin-top: 7px" CELLSPACING=0>
							<tr style="border: 1px solid #ddd">	
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd; text-align: center">DEST./ROUTE</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd; text-align: center">Invoice/DR #</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd; text-align: center">Time In</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd; text-align: center">Time Out</td>
								<td style="border-bottom: 1px solid #ddd; text-align: center">Received By</td>
							</tr>
							<tr style="border: 1px solid #ddd">	
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd">&nbsp;</td>
							</tr>
							<tr style="border: 1px solid #ddd">	
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd">&nbsp;</td>
							</tr>
							<tr style="border: 1px solid #ddd">	
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd">&nbsp;</td>
							</tr>
							<tr style="border: 1px solid #ddd">	
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd">&nbsp;</td>
							</tr>
							<tr style="border: 1px solid #ddd">	
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd">&nbsp;</td>
							</tr>
							<tr style="border: 1px solid #ddd">	
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd">&nbsp;</td>
							</tr>
							<tr style="border: 1px solid #ddd">	
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd">&nbsp;</td>
							</tr>
							<tr style="border: 1px solid #ddd">	
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd">&nbsp;</td>
							</tr>
							<tr style="border: 1px solid #ddd">	
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd">&nbsp;</td>
							</tr>
							<tr style="border: 1px solid #ddd">	
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd">&nbsp;</td>
							</tr>
						</table>
					</td>
					<td style="vertical-align: text-top; padding-left: 7px; padding-right: 7px; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif"> 
						<table style="border: 1px solid #ddd; width: 100%; font-size: 11px; margin-top: 7px" CELLSPACING=0>
							<tr style="border: 1px solid #ddd" >	
								<td style="border-bottom: 1px solid #ddd; text-align: center" colspan="2">TRAVEL FUND LIQUIDATION</td> 
							</tr>
							<tr style="border: 1px solid #ddd">	
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd">Travel Fund</td>
								<td style="border-bottom: 1px solid #ddd; width: 70px">php</td> 
							</tr>
							<tr style="border: 1px solid #ddd">	
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd">Toll, Parking & Entrance</td>
								<td style="border-bottom: 1px solid #ddd; width: 70px">php</td> 
							</tr>
							<tr style="border: 1px solid #ddd">	
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd">Fuel & Others (SOP, etc)</td>
								<td style="border-bottom: 1px solid #ddd; width: 70px">php</td> 
							</tr>
							<tr style="border: 1px solid #ddd">	
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd">Allowance</td>
								<td style="border-bottom: 1px solid #ddd; width: 70px">php</td> 
							</tr>
							<tr style="border: 1px solid #ddd">	
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd">TOTAL AMOUNT</td>
								<td style="border-bottom: 1px solid #ddd; width: 70px">php</td> 
							</tr>
							<tr style="border: 1px solid #ddd">	
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd">Returnable</td>
								<td style="border-bottom: 1px solid #ddd; width: 70px">php</td> 
							</tr>
							<tr style="border: 1px solid #ddd">	
								<td style="border-right: 1px solid #ddd">Refundable</td>
								<td style="">php</td> 
							</tr>

						</table>
						<table style="width: 100%">
							<tr>	
								<td style=" text-align: center">
									<span style="font-size: 11px">This is to authorize the Company to deduct <br/>
									the unreturned amount from my payroll</span>
								</td> 
							</tr>
						</table>
						
					</td> 
				</tr>
			</table>
			<table style="width: 100%; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 11px">
				<tr>	
					<td style=" vertical-align: top">
						<span>Dispatched By: {{details.created_by ? details.created_by.firstname+" "+details.created_by.lastname : "N/A"}} </span>
					</td> 
					<td style=" text-align: left; vertical-align: top; width: 40%">
						<table>
							<tr>
								<td style="font-size: 11px">Driver: &nbsp;</td>
								<td style="border-bottom: 1px solid black; width: 190px; font-size: 11px">
									&nbsp;
								</td>
							</tr>
							<tr>
								<td style="font-size: 11px">Helper 1: &nbsp;</td>
								<td style="border-bottom: 1px solid black; width: 190px; font-size: 11px">
									&nbsp;
								</td>
							</tr>
							<tr>
								<td style="font-size: 11px">Helper 2: &nbsp;</td>
								<td style="border-bottom: 1px solid black; width: 190px; font-size: 11px">
									&nbsp;
								</td>
							</tr>
						</table>
					</td> 
				</tr>
			</table>
			<!-- <hr ng-show="page=='1'"> -->
		</div>
		<div class="" ng-repeat="page in print" style="border-top: 1px solid #ddd; margin-top: 10px; padding-top: 10px">
			<table style="width: 100%">
				<tr>
					<td>
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 20px; font-weight: bold">MARVEL TRUCKING</div>
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 9px">Marvel Trucking Solutions Inc</div>
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 9px">Mercedes Business Park, Mercedes Ave, Rizal</div>
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 9px">BIR-TIN: 009-788-189-000</div>
					</td>
					<td style="vertical-align: text-top; text-align: right">
						<span style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-weight: bold">
							<span style="font-size: 20px">WB No. &nbsp;</span>
							<span style="font-size: 14px">{{details.booking_no ? details.booking_no : "N/A"}}</span>
						</span>
					</td>
				</tr>
			</table>
			<br/>
			<table style="width: 100%; font-size: 11px">
				<tr>
					<td style="padding-right: 7px; width: 31%"> 
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Customer</div>
						<div style="border-bottom: 1px solid black;  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-left:10px; padding-bottom: 7px; margin-top:-7px">{{details.customer ? details.customer.customer_name : "N/A"}}</div> 
					</td>
					<td style="vertical-align: text-top; padding-left: 7px; padding-right: 7px; width: 31%">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Type</div>
						<div style="border-bottom: 1px solid black;  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-left:10px; padding-bottom: 7px; margin-top:-7px">{{details.booking_type ? details.booking_type.setting_name : "N/A"}}</div> 
					</td>
					<td style="vertical-align: text-top; padding-left: 7px; width: 31%">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Delivery Date</div>
						<div style="border-bottom: 1px solid black; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-left:10px; padding-bottom: 7px; margin-top:-7px">{{details.delivery_date? moment.unix(details.delivery_date).format('MMMM DD, YYYY') : "N/A"}}</div> 
					</td>
				</tr>
				<tr>
					<td style="padding-right: 7px"> 
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Origin</div>
						<div style="border-bottom: 1px solid black;  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-left:10px; padding-bottom: 7px; margin-top:-7px">{{details.origin ? details.origin.setting_name : "N/A"}}</div> 
					</td>
					<td style="vertical-align: text-top; padding-left: 7px; padding-right: 7px">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Vehicle</div>
						<div style="border-bottom: 1px solid black;  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-left:10px; padding-bottom: 7px; margin-top:-7px">{{details.vehicle ? details.vehicle.plate_no : "N/A"}}</div> 
					</td>
					<td style="vertical-align: text-top; padding-left: 7px">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Fuel PO</div>
						<div style="border-bottom: 1px solid black; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-left:10px; padding-bottom: 7px; margin-top:-7px">{{details.fuel_po ? details.fuel_po : "N/A"}}</div> 
					</td>
				</tr>
				<tr>
					<td style="padding-right: 7px"> 
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Driver</div>
						<div style="border-bottom: 1px solid black; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-left:10px; padding-bottom: 7px; margin-top:-7px">{{details.driver ? details.driver.name : "N/A"}}</div> 
					</td>
					<td style="vertical-align: text-top; padding-left: 7px; padding-right: 7px">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Helper 1</div>
						<div style="border-bottom: 1px solid black;  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-left:10px; padding-bottom: 7px; margin-top:-7px">{{details.helper_a ? details.helper_a.name : "N/A"}}</div> 
					</td>
					<td style="vertical-align: text-top; padding-left: 7px">
						<div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Helper 2</div>
						<div style="border-bottom: 1px solid black; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-left:10px; padding-bottom: 7px; margin-top:-7px">{{details.helper_b ? details.helper_b.name : "N/A"}}</div> 
					</td>
				</tr>
				<tr>
					<td style="padding-right: 7px" colspan="3"> 
						<div style="border-bottom: 1px solid black; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px">Remark: {{(details.remarks && details.remarks != "") ? details.remarks : "N/A"}}</div> 
					</td> 
				</tr>
				<tr>
					<td style="padding-right: 7px; vertical-align: top; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif" colspan="2" > 
						<table style="border: 1px solid #ddd; width: 100%; font-size: 11px; margin-top: 7px" CELLSPACING=0>
							<tr style="border: 1px solid #ddd">	
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd; text-align: center">DEST./ROUTE</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd; text-align: center">Invoice/DR #</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd; text-align: center">Time In</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd; text-align: center">Time Out</td>
								<td style="border-bottom: 1px solid #ddd; text-align: center">Received By</td>
							</tr>
							<tr style="border: 1px solid #ddd">	
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd">&nbsp;</td>
							</tr>
							<tr style="border: 1px solid #ddd">	
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd">&nbsp;</td>
							</tr>
							<tr style="border: 1px solid #ddd">	
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd">&nbsp;</td>
							</tr>
							<tr style="border: 1px solid #ddd">	
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd">&nbsp;</td>
							</tr>
							<tr style="border: 1px solid #ddd">	
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd">&nbsp;</td>
							</tr>
							<tr style="border: 1px solid #ddd">	
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd">&nbsp;</td>
							</tr>
							<tr style="border: 1px solid #ddd">	
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd">&nbsp;</td>
							</tr>
							<tr style="border: 1px solid #ddd">	
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd">&nbsp;</td>
							</tr>
							<tr style="border: 1px solid #ddd">	
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd">&nbsp;</td>
							</tr>
							<tr style="border: 1px solid #ddd">	
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">&nbsp;</td>
								<td style="border-bottom: 1px solid #ddd">&nbsp;</td>
							</tr>
						</table>
					</td>
					<td style="vertical-align: text-top; padding-left: 7px; padding-right: 7px; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif"> 
						<table style="border: 1px solid #ddd; width: 100%; font-size: 11px; margin-top: 7px" CELLSPACING=0>
							<tr style="border: 1px solid #ddd" >	
								<td style="border-bottom: 1px solid #ddd; text-align: center" colspan="2">TRAVEL FUND LIQUIDATION</td> 
							</tr>
							<tr style="border: 1px solid #ddd">	
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd">Travel Fund</td>
								<td style="border-bottom: 1px solid #ddd; width: 70px">php</td> 
							</tr>
							<tr style="border: 1px solid #ddd">	
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd">Toll, Parking & Entrance</td>
								<td style="border-bottom: 1px solid #ddd; width: 70px">php</td> 
							</tr>
							<tr style="border: 1px solid #ddd">	
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd">Fuel & Others (SOP, etc)</td>
								<td style="border-bottom: 1px solid #ddd; width: 70px">php</td> 
							</tr>
							<tr style="border: 1px solid #ddd">	
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd">Allowance</td>
								<td style="border-bottom: 1px solid #ddd; width: 70px">php</td> 
							</tr>
							<tr style="border: 1px solid #ddd">	
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd">TOTAL AMOUNT</td>
								<td style="border-bottom: 1px solid #ddd; width: 70px">php</td> 
							</tr>
							<tr style="border: 1px solid #ddd">	
								<td style="border-bottom: 1px solid #ddd; border-right: 1px solid #ddd">Returnable</td>
								<td style="border-bottom: 1px solid #ddd; width: 70px">php</td> 
							</tr>
							<tr style="border: 1px solid #ddd">	
								<td style="border-right: 1px solid #ddd">Refundable</td>
								<td style="">php</td> 
							</tr>

						</table>
						<table style="width: 100%">
							<tr>	
								<td style=" text-align: center">
									<span style="font-size: 11px">This is to authorize the Company to deduct <br/>
									the unreturned amount from my payroll</span>
								</td> 
							</tr>
						</table>
						
					</td> 
				</tr>
			</table>
			<table style="width: 100%; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 11px">
				<tr>	
					<td style=" vertical-align: top">
						<span>Dispatched By: {{details.created_by ? details.created_by.firstname+" "+details.created_by.lastname : "N/A"}} </span>
					</td> 
					<td style=" text-align: left; vertical-align: top; width: 40%">
						<table>
							<tr>
								<td style="font-size: 11px">Driver: &nbsp;</td>
								<td style="border-bottom: 1px solid black; width: 190px; font-size: 11px">
									&nbsp;
								</td>
							</tr>
							<tr>
								<td style="font-size: 11px">Helper 1: &nbsp;</td>
								<td style="border-bottom: 1px solid black; width: 190px; font-size: 11px">
									&nbsp;
								</td>
							</tr>
							<tr>
								<td style="font-size: 11px">Helper 2: &nbsp;</td>
								<td style="border-bottom: 1px solid black; width: 190px; font-size: 11px">
									&nbsp;
								</td>
							</tr>
						</table>
					</td> 
				</tr>
			</table>
			<!-- <hr ng-show="page=='1'"> -->
		</div>
	</div>

</template>

<style scoped>
@page { size: auto;  margin: 0mm; }
.test{
	color: blue
}
	@media print {
      .col-special-1, .col-special-2, .col-special-3, .col-special-4, .col-special-5, .col-special-6,
      .col-special-7, .col-special-8, .col-special-9, .col-special-10, .col-special-11, .col-special-12 {
           float: left;               
      }

      .col-special-12 {
           width: 100%;
      }

      .col-special-11 {
           width: 91.66666666666666%;
      }

      .col-special-10 {
           width: 83.33333333333334%;
      }

      .col-special-9 {
            width: 75%;
      }

      .col-special-8 {
            width: 66.66666666666666%;
      }

       .col-special-7 {
            width: 58.333333333333336%;
       }

       .col-special-6 {
            width: 50%;
       }

       .col-special-5 {
            width: 41.66666666666667%;
       }

       .col-special-4 {
            width: 33.33333333333333%;
       }

       .col-special-3 {
            width: 25%;
       }

       .col-special-2 {
              width: 16.666666666666664%;
       }

       .col-special-1 {
              width: 8.333333333333332%;
        }     
}
</style>

<script>     
	import moment from 'moment';
	export default{

		props: { 
			details: {
				booking_no: "",



				equipment: "",
				vehicle_type_id: "",
				maker_model_id: "",
				origin_id: "",
				vendor_id: "",
				driver_id: "",
				body_no: "",
				year: "",
				with_gps: "",
				company_owned: true,
				plate_no: "",
				registration_date: "",
				asset_no: "",
				engine_no: "",
				engine_size: "",
				chassis_no: "",
				transmission_type: "",
				transfer_date: "",
				color: "",
				fuel_type: "",
				later_renewal_date: "",
				acquisition_date: "",
				acquisition_price: "",
				remarks: "",
				vehicle_photo: "",
				or_photo: "",
				cr_photo: "",
				insurance_photo: "",
				status: "active", 
				location: ""
			}
		},

		data(){
			return{
				moment
			}
		}

	}
</script>
