<template>
	<CModal title="Booking Charges" :show.sync="modalState" color="info" size="xl"> 
		<form @submit.prevent="submit">
    	
    	<div>
			<fieldset class="fieldset broken-line">
						<legend>Booking charges</legend>
    		
					<CRow>
						<CCol lg="3">
							<label class="mb-1">Material Code <span class="text-danger">*</span></label>
							<CInput
			                  v-model="dataParams.material_code" 
							  :required=true
			                />
						</CCol>
						<CCol lg="9">
							<label class="mb-1">Description <span class="text-danger">*</span></label>
							<CInput
			                  v-model="dataParams.description" 
			                />
						</CCol>
						<CCol lg="3">
							<label class="mb-1">Unit price <span class="text-danger">*</span></label>
							<CInput
			                 
								@keypress="isNumber($event)"
			                  v-model="dataParams.unit_price" 
			                  @input="priceChange"
							  :required=true
			                />
						</CCol>
						<CCol lg="3">
							<label class="mb-1">Qty <span class="text-danger">*</span></label>
							<CInput
			                
							  type="number"
			                  v-model="dataParams.quantity" 
			                  @input="priceChange"
							  :required=true
			                />
						</CCol>
						<CCol lg="3">
							<div class="form-group"> 
								<label class="mb-1">Rate</label>
								<v-select 
									label="label" 
									:options="[
										{ label: '1' },
										{ label: '1.5' },
										{ label: '2' },
										{ label: '2.5' },
										{ label: '3' },
										{ label: '3.5' },
										{ label: '4' },
										{ label: '4.5' },
										{ label: '5' },
										{ label: '5.5' },
										
										
									]"
									:reduce="item => item.label"
									v-model="dataParams.rate"
									placeholder="Select.." 
								>
								</v-select>
							</div>
						</CCol>
						<CCol lg="3">
							<label class="mb-1">Total Charge</label>
							<CInput
			                  disabled
							  type="number"
			                  v-model="dataParams.total_charge" 
			                />
						</CCol>
					</CRow>
					<CRow>
						<CCol lg="4">
							<div class="form-group"> 
								<label class="mb-1">Charge Type <span class="text-danger">*</span> </label>
								<v-select 
									label="label" 
									:options="[
										{ label: 'Short/Lacking' },
										{ label: 'Damage' },
										{ label: 'Thawed/Melted' },
										{ label: 'Invoice Related' },
										{ label: 'No Show' },
										{ label: 'Others' }
									]"
									:reduce="item => item.label"
									v-model="dataParams.charge_type"
									placeholder="Select..." 
									:required=true
								>
								<template #search="{attributes, events}">
											<input
												class="vs__search"
												:required="!dataParams.charge_type"
												v-bind="attributes"
												v-on="events"
											/>
								</template>
								</v-select>
							</div>
						</CCol>
						<CCol lg="4">
							<div class="form-group"> 
								<label class="mb-1">Charge To  <span class="text-danger">*</span></label>
								<v-select 
									label="label" 
									:options="charge_to_options"
									:reduce="item => item.label"
									v-model="dataParams.charge_to"
									placeholder="Select..." 
									:required=true
								>
								<template #search="{attributes, events}">
											<input
												class="vs__search"
												:required="!dataParams.charge_to"
												v-bind="attributes"
												v-on="events"
											/>
								</template>
								</v-select>
								
							</div>
						</CCol>
						<CCol lg="2">
							<label class="mb-1">Charge No.  <span class="text-danger">*</span></label>
							<CInput
			                  v-model="dataParams.charge_ref_no" 
							  :required=true
			                />
						</CCol> 
						<CCol lg="2">
							<div class="form-group"> 
								<label class="mb-1">Status <span class="text-danger">*</span></label>
								<v-select 
									label="label" 
									:options="[
										{ label: 'Pending' },
										{ label: 'Recovered' },
										{ label: 'Paid' }
									]"
									:reduce="item => item.label"
									v-model="dataParams.status"
									placeholder="Select status" 
									:required=true
								>
								<template #search="{attributes, events}">
											<input
												class="vs__search"
												:required="!dataParams.status"
												v-bind="attributes"
												v-on="events"
											/>
								</template>
								</v-select>
							</div>
						</CCol>
					</CRow>
		<div slot="footer" class="w-100">
			<CButton v-if="config.getPermission('booking_charges').create" size="sm" style="border-radius: .2rem; color: white;" color="info" class="ml-1 mr-1 float-right px-3" type="submit">
               Save
            </CButton>  
			<CButton size="sm" v-if="dataParams.booking_id" style="border-radius: .2rem; color: white;" color="danger"  @click="clear()" class="ml-1 mr-1 float-right px-3">
               Clear
			</CButton>  
    		
        </div>
		
			</fieldset>
    	</div>
		<div class="pl-1 pr-1 pb-2"> 
    		<CRow class="mb-2">
        		<CCol lg="12">
        			<CDataTable
				      	:items="charge_list.data"
				      	:fields="[
							{
								key: 'material_code', 
								label: 'Material Code'
							},
							{
								key: 'description', 
								label: 'Description'
							},
							{
								key: 'unit_price', 
								label: 'Unit Price'
							},
							{
								key: 'quantity', 
								label: 'Qty'
							},
							{
								key: 'rate_holder', 
								label: 'Rate'
							},
							{
								key: 'total_charge', 
								label: 'Total Charge'
							},
							{
								key: 'charge_type', 
								label: 'Charge Type'
							},
							{
								key: 'charge_to', 
								label: 'Charge To'
							},
							{
								key: 'charge_ref_no', 
								label: 'Charge No.'
							},
							{
								key: 'status', 
								label: 'Status'
							}, 
							{
								key: 'action', 
								label: 'Action'
							}
						]"
						hover
						striped
						outlined
						
				    >   
				    	<template #action="{item}">
							<td style="padding: 5px; text-transform: capitalize;">
								<CDropdown 
					                toggler-text="Select"  
									size="sm"
					                color="info"
					            >
					            	<CDropdownItem @click="updatePrepare(item)" v-if="item.status != 'Paid' && config.getPermission('booking_charges').update"> <i class="fa fa-pencil-square-o"> Update </i> </CDropdownItem>   
					                <CDropdownItem @click="confirmDelete(item)" v-if="config.getPermission('booking_charges').delete"> <i class="fa fa-trash"> Delete </i> </CDropdownItem> 
					            </CDropdown>
	 						</td>
				      	</template>
				    </CDataTable> 
        		</CCol> 
        	</CRow> 
    	</div>
    	
        </form>
    	<div slot="footer" class="w-100"></div>
    </CModal>
</template>
<script>
	import config from '../../config.js';
	import axios from '../../axios';
	import Swal from 'sweetalert2'; 
	import Datepicker from 'vuejs-datepicker';
	import vSelect from 'vue-select'
	import 'vue-select/dist/vue-select.css';

	export default{

		props: {
			showModal: false,
			details: {
				remarks: ""
			}
		},
		components: {vSelect},
		data(){
			return{
				config,
				charge_to_options: [
					{ label: 'Subcon' },
					{ label: 'Not-chargeable' },
					{ label: 'Personnel-helpers' },
					{ label: 'Personnel-driver' },
					{ label: 'Personnel-all' }
				],
				bookingParams: {
					remarks: ""
				},
				charge_list: {
					data: []
				},
				dataParams: {
					material_code: "",
			        description: "",
			        unit_price: "",
			        quantity: "",
			        rate: "",
			        total_charge: "",
			        charge_type: "",
			        charge_to: "",
			        charge_ref_no: "",
			        status: "",
			        booking_id: "",
					user_id:"",
				},
				modalState: false,
			}
		},
		watch: {
			details: {
				deep: true,
				handler(){
					console.log("===")
					console.log(this.details)
					console.log("===")
					this.bookingParams = this.details;
					// if(!this.details.driver){
					// 	this.charge_to_options = [ 
					// 		{ label: 'Not-chargeable' },
					// 		{ label: 'Personnel-helpers' }
					// 	]
					// }

					if(this.details.is_subcon == 1){
						this.charge_to_options = [ 
							{ label: 'Not-chargeable' },
							{ label: 'Subcon' }
						]
					}

					else if(this.details.is_subcon == 0 && this.details.driver && (this.details.helper_a || this.details.helper_b)){
						this.charge_to_options = [
							{ label: 'Not-chargeable' },
							{ label: 'Personnel-helper/s' },
							{ label: 'Personnel-driver' },
							{ label: 'Personnel-all' }
						]
					}
					else if(this.details.is_subcon == 0 && this.details.driver && (!this.details.helper_a && !this.details.helper_b)){
						this.charge_to_options = [ 
							{ label: 'Not-chargeable' },
							{ label: 'Personnel-driver' }
						]
					}
				
					
					
					// else{
					// 	this.charge_to_options = [
					// 		{ label: 'Subcon' },
					// 		{ label: 'Not-chargeable' }
					// 	]
					// }
					// if(!this.details.helper_a && !this.details.helper_b){
					// 	this.charge_to_options = [
					// 		{ label: 'Not-chargeable' }, 
					// 		{ label: 'Personnel-driver' }
					// 	]
					// }
					// else{
					// 	if(this.details.driver){
					// 		this.charge_to_options = [ 
					// 			{ label: 'Not-chargeable' },
					// 			{ label: 'Personnel-helpers' },
					// 		]
					// 	}
					// }
				}
			},
			showModal: {
				handler(val){
					this.modalState = this.showModal
					if(!this.showModal){
						this.$emit('showModal', false) 
					} 
					if(val)
						this.getData()
				}
			},
			modalState: {
				handler(){
					if(!this.modalState){
						this.$emit('showModal', false) 
					}
					// this.getData()
				}
			}
		},
		mounted() {
			
		},
		methods: {
			clear() {
				this.dataParams = {
					material_code: "",
			        description: "",
			        unit_price: "",
			        quantity: "",
			        rate: "",
			        total_charge: "",
			        charge_type: "",
			        charge_to: "",
			        charge_ref_no: "",
			        status: "",
			        booking_id: "",
					user_id:"",
				}
			},
			submit(){

				// axios.post(config.api_path+'/bookingStatusDetail', this.dataParams)
				// .then(response=>{ 

					
					this.dataParams.booking_id = this.details.id;
					this.dataParams.user_id = config.getUserDetail('id');
					this.dataParams.rate= this.dataParams.rate ? this.dataParams.rate: null;
					if(this.dataParams.id){
						axios.put(config.api_path+'/booking_charges/'+this.dataParams.id, this.dataParams)
						.then(response => {
							this.getData();
							Swal.fire({
								title: 'Success!',
								text: 'Successfully update the charges.',
								icon: 'success', 
							})
							.then(response=>{ 
								
								this.dataParams = {
									material_code: "",
							        description: "",
							        unit_price: "",
							        quantity: "",
							        rate: "",
							        total_charge: "",
							        charge_type: "",
							        charge_to: "",
							        charge_ref_no: "",
							        status: "",
									user_id:"",
								}
							})
						})
					}
					else{
						axios.post(config.api_path+'/booking_charges', this.dataParams)
						.then(response => {
							this.getData();
							Swal.fire({
								title: 'Success!',
								text: 'Successfully added a booking charges.',
								icon: 'success', 
							})
							.then(response=>{ 
								
								this.dataParams = {
									material_code: "",
							        description: "",
							        unit_price: "",
							        quantity: "",
							        rate: "",
							        total_charge: "",
							        charge_type: "",
							        charge_to: "",
							        charge_ref_no: "",
							        status: "",
									user_id:"",
								}
							})
						})

					}
					
					
	    			

				// })
			},
			
			getData(){ 
				this.clear()
				axios.get(config.api_path+'/booking_charges?booking_id='+this.details.id)
				.then(response => {
					let data = response.data;
					this.charge_list = data;

					this.charge_list.data = data.data.map(item => Object.assign({
						rate_holder : (item.rate) ? parseFloat(item.rate).toFixed(1) : 'N/A'
					}, item));

					console.log(this.charge_list.data)
				})
					
	    		
	   //  		Swal.fire({
				// 	//  title: 'Declined?',
				// 		text: "Are you sure you want to decline this booking?",
				// 		icon: 'warning',
				// 		showCancelButton: true,
				// 		confirmButtonColor: '#3085d6',
				// 		cancelButtonColor: '#d33',
				// 		confirmButtonText: 'Yes,decline!'
				// 		}).then((result) => {
				// 		if (result.isConfirmed) {  
				// 			axios.put(config.api_path+'/booking/'+this.details.id, {status: "declined", remarks: this.bookingParams.remarks, user_id: config.getUserDetail('id'), status_update: true})
				// 			Swal.fire(
				// 			'Declined!',
				// 			'Booking has been declined.',
				// 			'success'
				// 			)
				// 			console.log('aaa')
				// 			this.$emit('showModal', false)
 			// 			}
				// })
				// .then(response=>{
				// 	// this.$emit('showModal', false)
				// })
			},
			confirmDelete(item){
				Swal.fire({ 
					text: "Are you sure you want to delete this booking charge?",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes'
					}).then((result) => {
					if (result.isConfirmed) {  
						axios.delete(config.api_path+'/booking_charges/'+item.id)
						.then(response => {
							this.getData();
						})
					}
				})
				.then(response=>{
					// this.$emit('showModal', false)
				})
			},
			updatePrepare(item){
				this.dataParams = item;
			},
			priceChange(){
				if(this.dataParams.unit_price != "" && this.dataParams.quantity != ""){
					this.dataParams.total_charge = this.dataParams.unit_price * this.dataParams.quantity
				}
			}

		}

	}
</script>