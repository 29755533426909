<template>
	<CModal title="UPDATE STATUS TO DISPATCHED" :show.sync="modalState" color="info" size="lg"> 
		<form @submit.prevent="confirm">
    	<div class="pl-3 pr-3 pb-3"> 
			 	<h5>
        			Booking # : {{details.booking_no}}
        		</h5> 
    		<CRow class="mb-2">
        		<CCol lg="6">
						<small class="mr-2">Delivery Date</small>
						<CInput
		                  disabled
		                  :value="moment.unix(details.delivery_date).format('MMMM DD, YYYY')" 
		                  type="text"
						  class="mb-2"
		                />
				</CCol>	
        		
				<CCol lg="6">
						<small class="mr-2">Vehicle No.</small>
						<CInput
		                  disabled
		                  :value="details.plate_no" 
		                  type="text"
						  class="mb-2"
		                />
				</CCol>		
				
        	</CRow>
        	<hr/>
			  

		
        	<CRow>
				
        		<CCol lg="12">
        			<div class="form-group"> 
						<label class="mb-1">Start/Dispatch Date Time <span class="text-danger">*</span> </label>
						<Datetime format="MM/DD/YYYY H:i" v-model="bookingParams.dispatch_datetime" :required="true"></Datetime>
					</div>
        			
        		</CCol> 
        	</CRow> 
        	
        	<CRow>
        		<CCol lg="12">
					<label class="mb-1">Fuel PO</label>
        			<CInput
		                  v-model="bookingParams.fuel_po"
		                 
		                />
        		</CCol> 
        	</CRow> 
			
        	<CRow>
        		<CCol lg="12">
					<label class="mb-1">Odometer Reading 
						<i class="fa fa-question-circle ml-1" title="Reading should be maximum of 6 characters." ></i>
					</label>
        			<CInput
		                  v-model="bookingParams.odometer" 
							type="number"
							required
							v-mask="'######'"
						  
		                />

						<div  class="mb-2" style="margin-top: -10px; font-size:11px " >
									<span>Current Odometer: {{ odometer_value }}
									</span>
						</div>
						<div  class="mb-2" style="margin-top: -5px; font-size:11px " >
        					<span class="text-danger">{{message}}</span>
        				</div>
        		</CCol> 
        	</CRow> 
        	<CRow>
        		<CCol lg="12">
					<label class="mb-1">Remarks </label>
        			<CInput
		                  v-model="bookingParams.remarks" 
		                />
        		</CCol> 
        	</CRow> 
        	
				
    	</div>
    	<div slot="footer" class="w-100">
    		<CButton size="sm" style="border-radius: .2rem; color: white;" color="info" class="ml-1 mr-1 float-right" type="submit">
                Confirm
            </CButton> 
    		<CButton size="sm" style="border-radius: .2rem; color: white;" color="danger" class="ml-1 mr-1 float-right" @click="cancel">
                Cancel
            </CButton>  
        </div>
        </form>
    	<div slot="footer" class="w-100"></div>
    </CModal>
</template>
<script>
	import config from '../../config.js';
	import axios from '../../axios';
	import Swal from 'sweetalert2'; 
	import Datetime from 'vuejs-datetimepicker';
	import vSelect from 'vue-select'
	import 'vue-select/dist/vue-select.css';
	import '../../style.css';
	import moment from 'moment';

	export default{
		mounted(){  
			

},

		props: {
			showModal: false,
			details: {
				remarks: "",
				booking_no: "",
				plate_no: ""
			}
		},
		components: {Datetime},
		data(){
			return{
				odometer_value:0, 
				dataParams: { 
					booking_id: ""
				},
				temp: {
					odometer: ""
				},
				bookingParams: {
					remarks: "",
					fuel_po: "", 
					dispatch_datetime: moment().format('MM/DD/YYYY HH:mm'),
					odometer: "0"
				},
				modalState: false,
				moment,
				message: ""
			}
		},
		watch: {
			details: {
				deep: true,
				handler(){
					this.bookingParams.remarks = this.details.remarks; 
					this.bookingParams.fuel_po = this.details.fuel_po;
					this.dataParams.booking_id = this.details.id;
					if(this.details.vehicle){
						// if(this.details.vehicle.odometer.length > 0){
						// 	this.bookingParams.odometer = this.details.vehicle.odometer[this.details.vehicle.odometer.length-1].reading
						// }

						this.getOdometerData(this.details.vehicle.id)
						
					}
				}
			},
			showModal: {
				handler(){
					this.modalState = this.showModal
					if(!this.showModal){
						this.$emit('showModal', false) 
					} 
				}
			},
			modalState: {
				handler(){
					if(!this.modalState){
						this.$emit('showModal', false) 
					} 
				}
			}
		},
		methods: {

			confirm(){
				this.message = ""
				this.bookingParams.user_id = config.getUserDetail('id');
				this.bookingParams.status_update = true;
				var temp_date = this.bookingParams.dispatch_datetime;
				
				this.bookingParams.temp_date = moment(temp_date).format('MM/DD/YYYY HH:mm');
				this.bookingParams.dispatch_datetime = moment(this.bookingParams.dispatch_datetime, "MM/DD/YYYY HH:mm").valueOf() / 1000;
				// axios.post(config.api_path+'/bookingStatusDetail', this.dataParams)
				// .then(response=>{ 

					this.bookingParams.status = "dispatched";
					axios.put(config.api_path+'/booking/'+this.details.id, this.bookingParams)
					.then(response=>{
						if(response.data.status == "error"){
							this.message = response.data.message; 
						}
						else{
							var text = 'Booking successfully updated status to dispatched!';
			    			Swal.fire({
								title: 'Success!',
								text,
								icon: 'success', 
							})
							.then(response=>{
								this.$emit('showModal', false)
							})
						}
					})
					

				// })
				
			},
			cancel(){ 
				this.$emit('showModal', false) 
			},
			getOdometerData(i){
		// this.data_loading.odometer = true;
				axios.get(`${config.api_path}/vehicle/odometer/${i}`).then(res => {
					const {data, status} = res.data;
					const odo = data?.odo_reading ?? "0";

					this.$set(this.bookingParams,'odometer', odo);
					this.odometer_value=odo;
					// this.data_loading.odometer = false;
				})
				.finally(fin => {
					// this.data_loading.odometer = false;
				})
			
	    },

		}

	}
</script>