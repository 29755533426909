<template>
	<CModal title="APPROVAL CONFIRMATION" :show.sync="modalState" color="info" size="lg"> 
		<form @submit.prevent="approve">
    	<div class="pl-3 pr-3 pb-3"> 
    		<CRow class="mb-2">
        		<CCol lg="12">
        			Are you sure you want to approve this entry?
        		</CCol> 
        	</CRow>
			<br>
        	<CRow class="mb-2">
        		<CCol lg="12">
        			<CTextarea
		                  label="Remarks"
		                  placeholder="Please enter remarks. Maximum of 255 only."
						  maxlength="250"
		                  v-model="bookingParams.remarks" 
		                />
        		</CCol> 
        	</CRow>
        	
    	</div>
    	<div slot="footer" class="w-100">
    		<CButton size="sm" style="border-radius: .2rem; color: white;" color="success" class="ml-1 mr-1 float-right" type="submit">
                Approve
            </CButton> 
    		<CButton size="sm" style="border-radius: .2rem; color: white;" color="danger" class="ml-1 mr-1 float-right" @click="decline">
                Decline
            </CButton>  
        </div>
        </form>
    	<div slot="footer" class="w-100"></div>
    </CModal>
</template>
<script>
	import config from '../../config.js';
	import axios from '../../axios';
	import Swal from 'sweetalert2'; 
	import Datepicker from 'vuejs-datepicker';
	import vSelect from 'vue-select'
	import 'vue-select/dist/vue-select.css';

	export default{

		props: {
			showModal: false,
			details: {
				remarks: ""
			}
		},
		data(){
			return{
				bookingParams: {
					remarks: ""
				},
				dataParams: {
					status: "approved"
				},
				modalState: false,
			}
		},
		watch: {
			details: {
				deep: true,
				handler(){
					this.bookingParams.remarks = this.details.remarks;
				}
			},
			showModal: {
				handler(){
					this.modalState = this.showModal
					if(!this.showModal){
						this.$emit('showModal', false) 
					} 
				}
			},
			modalState: {
				handler(){
					if(!this.modalState){
						this.$emit('showModal', false) 
					} 
				}
			}
		},
		methods: {

			approve(){

				// axios.post(config.api_path+'/bookingStatusDetail', this.dataParams)
				// .then(response=>{ 

					
					this.bookingParams.user_id = config.getUserDetail('id');
					this.bookingParams.status_update = true;
					
					this.bookingParams.status = "approved";
					axios.put(config.api_path+'/booking/'+this.details.id, this.bookingParams)
					
	    			Swal.fire({
					title: 'Success!',
					text: 'Booking successfully update status to approved!',
					icon: 'success', 
				})
					.then(response=>{
						this.$emit('showModal', false)
					})

				// })
			},
			
			decline(){ 
	    		Swal.fire({
					//  title: 'Declined?',
						text: "Are you sure you want to decline this booking?",
						icon: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#d33',
						cancelButtonColor: '#3085d6',
						confirmButtonText: 'Yes,decline!',
						reverseButtons: true,
						}).then((result) => {
						if (result.isConfirmed) {  
							axios.put(config.api_path+'/booking/'+this.details.id, {status: "declined", remarks: this.bookingParams.remarks, user_id: config.getUserDetail('id'), status_update: true})
							Swal.fire(
							'Declined!',
							'Booking has been declined.',
							'success'
							)
							console.log('aaa')
							this.$emit('showModal', false)
 						}
				})
				.then(response=>{
					// this.$emit('showModal', false)
				})
			}

		}

	}
</script>